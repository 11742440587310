/* eslint-disable sort-keys */
import {
  Backdrop,
  Box,
  Card,
  CardActionArea,
  CardContent,
  DialogContent,
  DialogTitle,
  Fab,
  Fade,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import { not, prop } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAnalytics } from 'hooks'
import {
  setIsSpotlightOpen,
  setTotalSteps,
  setType
} from 'components/spotlight/spotlightSlice'
import { setIsSidebarOpen } from 'components/sidebar/sidebarSlice'
import Icon from 'components/icon/Icon'

const ActionButton = () => {
  const dispatch = useDispatch()
  const { hide_resources, letters } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('laptop'))

  const [isOpen, setIsOpen] = useState(false)

  const handleAnalytics = title =>
    sendEvent('Help_modal', {
      'Element Title': title
    })

  return (
    <Box>
      <Fade in={isOpen}>
        <Box
          aria-labelledby="dialog-title"
          onClose={() => {
            setIsOpen(false)
            handleAnalytics('Close Modal')
          }}
          open={isOpen}
          role="dialog"
          sx={{
            backgroundColor: 'surface.high',
            borderRadius: 4,
            bottom: { mobile: 80, tablet: 32 },
            position: 'fixed',
            right: { mobile: 8, tablet: 104 },
            zIndex: 1401
          }}
        >
          <DialogTitle
            component="h4"
            id="dialog-title"
            sx={{
              fontSize: 20,
              mt: 2,
              textAlign: 'left',
              textTransform: 'none'
            }}
            tabIndex={-1}
          >
            YOU at {letters}
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: { mobile: 2, laptop: 3 }
            }}
          >
            <Typography variant="subtitle2">How can we help?</Typography>
            <Card>
              <CardActionArea
                onClick={() => {
                  setIsOpen(false)
                  if (isMobile) dispatch(setIsSidebarOpen(true))
                  setTimeout(() => {
                    dispatch(setIsSpotlightOpen(true))
                    dispatch(setType('introduction'))
                    dispatch(setTotalSteps(not(hide_resources) ? 7 : 6))
                  }, 500)
                  handleAnalytics('Take tour')
                }}
              >
                <CardContent
                  sx={{
                    p: 3
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="h5">Take a Guided Tour</Typography>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <Typography variant="body2">
                        Let us walk you through how to get the most out of You
                        at College.
                      </Typography>
                      <Icon>arrow_forward</Icon>
                    </Stack>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea
                LinkComponent={Link}
                onClick={() => {
                  setIsOpen(false)
                  handleAnalytics('Give Feedback')
                }}
                to="/feedback"
              >
                <CardContent
                  sx={{
                    p: 3
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="h5">Give Feedback</Typography>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <Typography variant="body2">
                        Do you have feedback about your experience using the
                        site or a question for the You at College team? Let us
                        hear it!
                      </Typography>
                      <Icon>arrow_forward</Icon>
                    </Stack>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          </DialogContent>
        </Box>
      </Fade>
      <Fab
        aria-label="help"
        onClick={() => {
          setIsOpen(not(isOpen))
          handleAnalytics(isOpen ? 'Close Modal' : 'Open Modal')
        }}
        sx={{
          ':hover': {
            backgroundColor: 'button.tonal.hover'
          },
          backgroundColor: 'primary.tonalContainer',
          bottom: { mobile: 16, laptop: 32 },
          position: 'fixed',
          right: { mobile: 16, laptop: 32 },
          // To have it above dialog overlay
          zIndex: 1401
        }}
      >
        {isOpen ? <Icon>close</Icon> : <Icon>question_mark</Icon>}
      </Fab>
      <Backdrop
        onClick={() => {
          setIsOpen(false)
          handleAnalytics('Close Modal')
        }}
        open={isOpen}
        sx={{
          zIndex: 1400
        }}
      />
    </Box>
  )
}

export default ActionButton
