/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { Backdrop, Box, Tooltip, useMediaQuery } from '@mui/material'
import { all, and, equals, inc, not, prop } from 'ramda'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCurrentStep,
  setIsSpotlightOpen,
  setTotalSteps,
  setType
} from './spotlightSlice'
import { useAnalytics } from 'hooks'

import IntroductionContent from './IntroductionContent'

// Placement can only be one of the types from MUI
// Dividing between mobile and laptop only
// as they may vary based on device width
// See Mui Tooltip documentation here:
// https://mui.com/material-ui/react-tooltip/#positioned-tooltips

// needsPadding should only be used for main area elements
// ie: something not in the sidebar
// this provides a better hightlight for the element
const SpotlightWrapper = ({
  children,
  hasHelperList = false,
  needsPadding = false,
  placement,
  spotlightType,
  step
}) => {
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('laptop'))
  const { currentStep, isSpotlightOpen, totalSteps, type } = useSelector(
    prop('spotlight')
  )
  const { sendEvent } = useAnalytics()

  const openCheck = useMemo(() => {
    if (
      all(equals(true))([
        isSpotlightOpen,
        equals(step, currentStep),
        equals(type, spotlightType)
      ])
    )
      return true
    return false
  }, [isSpotlightOpen, step, currentStep, type, spotlightType])

  const handleClose = useCallback(() => {
    // Analytics
    // leave on last step
    if (not(equals(totalSteps, currentStep)))
      sendEvent('Spotlight_tour', {
        Event: 'Leave',
        Step: inc(currentStep)
      })
    dispatch(setIsSpotlightOpen(false))
    dispatch(setType(''))
    dispatch(setCurrentStep(0))
    dispatch(setTotalSteps(null))
  }, [currentStep, dispatch, sendEvent, totalSteps])

  useEffect(() => {
    const handleKeydown = ({ keyCode }) => {
      // Key code 27 is escape
      if (equals(27, keyCode)) handleClose()
      return
    }
    window.addEventListener('keydown', handleKeydown)
    return () => window.removeEventListener('keydown', handleKeydown)
  }, [handleClose])

  return (
    <Box sx={{ width: 1 }}>
      <Tooltip
        open={openCheck}
        placement={
          isDesktop ? prop('laptop', placement) : prop('mobile', placement)
        }
        title={<IntroductionContent hasHelperList={hasHelperList} />}
      >
        <Box
          sx={{
            backgroundColor: openCheck ? 'surface.container' : 'transparent',
            borderRadius: 4,
            p: and(openCheck, needsPadding) ? { mobile: 0, tablet: 1 } : 0,
            position: 'relative',
            width: and(openCheck, needsPadding)
              ? { mobile: 1, laptop: 'calc(100% + 16px)' }
              : 1,
            zIndex: openCheck ? 1401 : 0
          }}
        >
          {children}
        </Box>
      </Tooltip>
      <Backdrop
        open={openCheck}
        sx={{
          zIndex: 1400
        }}
      />
    </Box>
  )
}

SpotlightWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  hasHelperList: PropTypes.bool,
  needsPadding: PropTypes.bool,
  placement: PropTypes.shape({
    mobile: PropTypes.oneOf([
      'top-start',
      'top',
      'top-end',
      'right-start',
      'right',
      'right-end',
      'bottom-start',
      'bottom',
      'bottom-end',
      'left-start',
      'left',
      'left-end'
    ]).isRequired,
    // eslint-disable-next-line sort-keys
    laptop: PropTypes.oneOf([
      'top-start',
      'top',
      'top-end',
      'right-start',
      'right',
      'right-end',
      'bottom-start',
      'bottom',
      'bottom-end',
      'left-start',
      'left',
      'left-end'
    ]).isRequired
  }).isRequired,
  spotlightType: PropTypes.string.isRequired,
  step: PropTypes.number
}

export default SpotlightWrapper
