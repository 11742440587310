/* eslint-disable no-useless-escape */
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { and, compose, isEmpty, not, path, tap, toLower } from 'ramda'
import { usePasswordResetEmailMutation } from 'api/authApi'
import { useLocalStorage } from 'hooks'
import Toast from 'components/toast/Toast'

const EmailReset = () => {
  const [email, setEmail] = useState('')
  const [isValid, setIsValid] = useState(false)

  const { clear } = useLocalStorage()

  const [triggerReset, { isSuccess }] = usePasswordResetEmailMutation()

  const handleReset = async () => {
    const body = new FormData()
    body.append('email', toLower(email))
    clear()
    try {
      await triggerReset(body)
    } catch (err) {
      console.warn('Error resetting password:', err)
    }
  }

  const validateEmail = email => {
    const regex =
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setIsValid(regex.test(email))
  }

  const renderHelper = () => {
    if (and(not(isValid), not(isEmpty(email))))
      return (
        <FormattedMessage
          defaultMessage="Hmm, this email address isn't valid."
          id="/g9wJ/"
        />
      )
    return (
      <FormattedMessage
        defaultMessage="Please enter your email address"
        id="/vJwLB"
      />
    )
  }

  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: 'center',
        height: 'calc(100vh - 220px)',
        justifyContent: 'center',
        width: 1
      }}
    >
      <Typography textAlign="center" variant="h1">
        <FormattedMessage defaultMessage="Recover Your Password" id="zZIKBr" />
      </Typography>
      <Box
        component="form"
        onSubmit={e => {
          e.preventDefault()
          handleReset()
        }}
        sx={{ maxWidth: 500, width: 1 }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gridGap: 16,
            justifyContent: 'center',
            width: 1
          }}
        >
          <TextField
            error={and(not(isValid), not(isEmpty(email)))}
            fullWidth
            helperText={renderHelper()}
            id="email"
            name="email"
            onChange={compose(
              validateEmail,
              tap(setEmail),
              path(['target', 'value'])
            )}
            value={email}
          />
          <Toast
            isShown={isSuccess}
            message={
              <FormattedMessage
                defaultMessage="If your email address is in our system you'll receive a link to reset"
                id="db9AEl"
              />
            }
          />
          <Button type="submit">
            <FormattedMessage defaultMessage="Submit" id="wSZR47" />
          </Button>
        </Box>
      </Box>
    </Stack>
  )
}

export default EmailReset
