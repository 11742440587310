import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store } from 'store/store'

import HeadComponent from './Head'
import AnalyticsComponent from './Analytics'
import LanguageHandler from 'components/language/LanguageHandler'
import SchoolLoader from 'components/school/SchoolLoader'
import AuthorizationHandler from 'components/authorization/AuthorizationHandler'
import AppFlowHandler from 'components/app-flow/AppFlowHandler'
import HistoryHandler from 'components/history/HistoryHandler'
import SessionHandler from 'components/session/SessionHandler'
import MuiThemeProvider from 'theme/MuiThemeProvider'

import UserLoader from 'components/user/UserLoader'
import AlertWrapper from 'components/alert/AlertWrapper'
import EmailOptInModal from 'components/modals/EmailOptInModalConnector'
import CrisisModal from 'components/modals/CrisisModalConnector'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import MainWrapper from 'components/main-wrapper/MainWrapper'
import AppRoutes from 'routes/AppRoutes'

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <MuiThemeProvider>
        <HeadComponent />
        <AnalyticsComponent />
        <LanguageHandler>
          <SchoolLoader>
            <HistoryHandler />
            <SessionHandler />
            <AuthorizationHandler />
            <AppFlowHandler />
            <AlertWrapper />
            <CrisisModal />
            <EmailOptInModal />
            <ConfirmationModal />
            <UserLoader />
            <MainWrapper>
              <AppRoutes />
            </MainWrapper>
          </SchoolLoader>
        </LanguageHandler>
        <script defer src="https://accounts.google.com/gsi/client" />
      </MuiThemeProvider>
    </Provider>
  </BrowserRouter>
)

export default App
