import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  isPrioritiesModalOpen: false,
  isSeeAll: false,
  showSeeAll: false
}

const prioritiesSlice = createSlice({
  initialState,
  name: 'priorities',
  reducers: {
    setIsPrioritesModalOpen: (state, { payload }) =>
      assoc('isPrioritiesModalOpen', payload, state),
    setIsSeeAll: (state, { payload }) => assoc('isSeeAll', payload, state),
    setShowSeeAll: (state, { payload }) => assoc('showSeeAll', payload, state)
  }
})

export const { setIsPrioritesModalOpen, setIsSeeAll, setShowSeeAll } =
  prioritiesSlice.actions

export default prioritiesSlice.reducer
