import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { isNil, path } from 'ramda'
import { useSelector } from 'react-redux'
import { isToday, parseISO } from 'date-fns'

import StatsBar from './stats-bar/StatsBar'

const Hero = () => {
  const genesis = useSelector(path(['user', 'genesis']))

  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: 'center',
        bgcolor: 'surface.low',
        borderRadius: 4,
        // eslint-disable-next-line sort-keys
        p: { mobile: 4, tablet: 2, laptop: 4 },
        width: 1
      }}
    >
      <Stack alignItems="center" spacing={1.5}>
        <Box
          sx={{
            backgroundImage: 'url(/vectors/you-logo.svg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: 18,
            width: 19
          }}
        />
        {isNil(genesis) ? (
          <>
            <Skeleton height={16} variant="text" width={99} />
            <Skeleton height={48} variant="text" width={374} />
          </>
        ) : (
          <>
            <Typography
              component="h1"
              sx={{
                display: 'block',
                textAlign: 'center'
              }}
              variant="overline"
            >
              Welcome {isToday(parseISO(genesis)) ? '' : 'Back'}
            </Typography>
            <Typography variant="h1">It&apos;s All About YOU</Typography>
          </>
        )}
      </Stack>
      {isNil(genesis) ? (
        <Skeleton
          height={40}
          sx={{ maxWidth: 722 }}
          variant="rectangular"
          width="100%"
        />
      ) : (
        <StatsBar />
      )}
    </Stack>
  )
}

export default Hero
