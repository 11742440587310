/* eslint-disable sort-keys */
import { Stack } from '@mui/material'
import { concat, equals, filter, isEmpty, not, prop, reduce } from 'ramda'
import { useSelector } from 'react-redux'
import EmptyState from './EmptyState'
import { useMemo } from 'react'

import Heading from './Heading'
import LinkList from './LinkList'
import CardsList from './CardList'

const Priorities = () => {
  const { display_priorities_as_images, interestedSubdomains, userDomains } =
    useSelector(prop('user'))

  // Get user priorities
  // Grab userDomains then reduce to create the different subdomains
  // from each of the different user domains
  // THEN
  // Filter the selected user subdomains out of the subdomian reduce
  // return the selected subdomains
  // this return determines the component rendered
  const priorities = useMemo(() => {
    const subdomains = reduce(
      (subdomains, domain) => concat(subdomains, prop('subdomains', domain)),
      [],
      userDomains
    )
    const interested = filter(
      ({ id }) => interestedSubdomains.some(sub => equals(id, prop('id', sub))),
      subdomains
    )

    return interested
  }, [interestedSubdomains, userDomains])

  const Component = useMemo(() => {
    if (not(display_priorities_as_images))
      return <LinkList priorities={priorities} />
    return <CardsList priorities={priorities} />
  }, [priorities, display_priorities_as_images])

  return (
    <Stack
      alignItems="center"
      spacing={3}
      sx={{
        width: 1
      }}
    >
      {isEmpty(priorities) ? <EmptyState /> : <Heading>{Component}</Heading>}
    </Stack>
  )
}

export default Priorities
