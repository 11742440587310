import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { and, any, equals, not } from 'ramda'
import { setIsSidebarOpen } from './sidebarSlice'
import { useDispatch } from 'react-redux'
import Icon from 'components/icon/Icon'

const SidebarMenuItem = ({
  link,
  icon,
  title = null,
  isShown = true,
  target = null
}) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const anyCheck = check => pathname.includes(check)

  const activeCheck = () => {
    if (equals(link, pathname)) return true
    if (
      and(
        equals(link, '/explore'),
        any(anyCheck)(['/succeed/', '/matter/', '/thrive/'])
      )
    )
      return true
    if (and(link.includes('/goals'), equals(pathname, '/goals'))) return true
    if (and(link.includes('/self-checks'), pathname.includes('/self-checks')))
      return true
    return false
  }

  if (not(isShown)) return null

  const handleClick = () => dispatch(setIsSidebarOpen(false))

  return (
    <Button
      aria-current={activeCheck() ? 'page' : ''}
      LinkComponent={Link}
      onClick={handleClick}
      selected={activeCheck()}
      startIcon={<Icon fill={activeCheck()}>{icon}</Icon>}
      target={target}
      to={link}
      variant="sidebar"
    >
      {title}
    </Button>
  )
}

SidebarMenuItem.propTypes = {
  icon: PropTypes.string.isRequired,
  isShown: PropTypes.bool,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  target: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default SidebarMenuItem
