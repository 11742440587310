import { api as baseQuery } from './baseQuery'
import { prop } from 'ramda'

export const userApi = baseQuery.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    getFavorites: query({
      providesTags: ['favorite'],
      query: params => ({
        params,
        url: '/favorites'
      })
    }),
    getPublicUser: query({
      providesTags: ['public user'],
      query: () => '/v4/user/publicUser'
    }),
    getUser: query({
      providesTags: ['user'],
      query: () => '/user-data'
    }),
    getUserAppType: query({
      providesTags: ['user app type'],
      query: () => '/v4/user/appType'
    }),
    getUserAttributes: query({
      providesTags: ['user attributes'],
      query: () => '/v4/user/attributesReduced'
    }),
    getUserDomains: query({
      providesTags: ['user domains'],
      query: () => '/user-domains'
    }),
    getUserDomainsStudentContent: query({
      providesTags: ['student content'],
      query: () => '/user-domains?toggle_primary=1'
    }),
    getUserInterestedSubdomains: query({
      providesTags: ['user subdomains'],
      query: () => '/v4/user/interestedSubdomains'
    }),
    getUserProfileAnswers: query({
      providesTags: ['user profile answers'],
      query: () => '/v4/user/profileAnswers'
    }),
    getValidRoleTypes: query({
      providesTags: ['role types'],
      query: () => '/valid-role-types'
    }),
    reVerifyUser: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: '/user/resend-verification'
      })
    }),
    reengagementUnsubscribe: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: '/reengagement-email-unsubscribe'
      })
    }),
    sendEmail: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: '/user/send-email'
      })
    }),
    sendFeedback: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: '/feedback/save'
      })
    }),
    updateInterests: mutation({
      invalidatesTags: ['user'],
      query: body => ({
        body,
        method: 'POST',
        url: '/interests'
      })
    }),
    updateOnboardedUser: mutation({
      invalidatesTags: ['user'],
      query: body => ({
        body,
        method: 'POST',
        url: '/user/onboard'
      })
    }),
    updateUser: mutation({
      invalidatesTags: [
        'new cards',
        'card',
        'cards',
        'campus goals',
        'completed goals',
        'goal',
        'goals',
        'profile questions',
        'pick up cards',
        'reality score',
        'scores',
        'score',
        'self check',
        'about',
        'favorites',
        'student content',
        'subdomain',
        'user app type',
        'user attributes',
        'user domains',
        'user subdomains'
      ],
      query: body => ({
        body,
        method: 'POST',
        url: '/profile'
      })
    }),
    updateUserQuestions: mutation({
      invalidatesTags: [
        'user app type',
        'user profile answers',
        'user attributes',
        'profile questions'
      ],
      query: body => ({
        body: { answers: prop('answers', body) },
        method: 'POST',
        url: `/profile-question/${prop('profile_question_id', body)}/answers`
      })
    }),
    verifyUser: mutation({
      query: body => ({
        method: 'POST',
        url: `/user/verification/${prop('email', body)}/${prop('token', body)}`
      }),
      transformResponse: (res, meta, req) => ({
        headers: prop('response', meta),
        req,
        res
      })
    }),
    welcomeUnsubscribe: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: '/email-unsubscribe'
      })
    })
  }),
  reducerPath: 'userApi'
})

export const {
  useGetFavoritesQuery,
  useGetPublicUserQuery,
  useGetUserQuery,
  useGetUserAppTypeQuery,
  useGetUserAttributesQuery,
  useGetUserDomainsQuery,
  useGetUserDomainsStudentContentQuery,
  useGetUserInterestedSubdomainsQuery,
  useGetUserProfileAnswersQuery,
  useGetValidRoleTypesQuery,
  useReVerifyUserMutation,
  useReengagementUnsubscribeMutation,
  useSendEmailMutation,
  useSendFeedbackMutation,
  useUpdateInterestsMutation,
  useUpdateOnboardedUserMutation,
  useUpdateUserMutation,
  useUpdateUserQuestionsMutation,
  useVerifyUserMutation,
  useWelcomeUnsubscribeMutation
} = userApi
