import { Stack, Typography } from '@mui/material'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'

const Heading = () => {
  const { is_secondary_user } = useSelector(prop('user'))

  return (
    <Stack
      alignItems="center"
      spacing={3}
      sx={{
        '& > *': {
          position: 'relative',
          zIndex: 2
        },
        ':after': {
          backgroundColor: '#211F1C',
          bottom: 0,
          content: is_secondary_user && '""',
          left: 0,
          opacity: 0.7,
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 1
        },
        ':before': {
          backgroundImage: `url(/vectors/${is_secondary_user ? 'staff-check-in-bg.jpg' : 'student-check-in-bg.svg'})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          bottom: 0,
          content: '""',
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          width: 1,
          zIndex: 0
        },
        position: 'relative',
        px: 4,
        py: 3,
        width: 1,
        zIndex: 0
      }}
    >
      <Typography sx={{ color: '#FCF8F0' }} variant="h2">
        Check-in With Yourself
      </Typography>
      <Typography component="span" sx={{ color: '#FCF8F0' }} variant="h6">
        Tell us how you&apos;re feeling and we&apos;ll offer suggested resources
        to explore.
      </Typography>
    </Stack>
  )
}

export default Heading
