import {
  useGetPublicUserQuery,
  useGetUserAppTypeQuery,
  useGetUserAttributesQuery,
  useGetUserInterestedSubdomainsQuery,
  useGetUserProfileAnswersQuery
} from 'api/userApi'
import { useLocalStorage } from 'hooks'
import { any, equals, isNil, not, prop } from 'ramda'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setUserData, updateUserData } from 'store/userSlice'
import { onAuthRoute, onPublicRoute } from 'utils/route-helpers'

const UserLoader = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { get } = useLocalStorage()

  const sessionToken = get()

  const {
    data,
    isFetching = false,
    isSuccess = false
  } = useGetUserAttributesQuery(null, {
    skip: any(equals(true))([
      onPublicRoute(pathname),
      onAuthRoute(pathname),
      isNil(sessionToken)
    ])
  })

  const { data: interestedSubdomains } = useGetUserInterestedSubdomainsQuery(
    null,
    {
      skip: any(equals(true))([
        onPublicRoute(pathname),
        onAuthRoute(pathname),
        isNil(sessionToken)
      ])
    }
  )

  const { data: publicUser } = useGetPublicUserQuery(null, {
    skip: any(equals(true))([
      onPublicRoute(pathname),
      onAuthRoute(pathname),
      isNil(sessionToken)
    ])
  })

  const { data: profileAnswers } = useGetUserProfileAnswersQuery(null, {
    skip: any(equals(true))([
      onPublicRoute(pathname),
      onAuthRoute(pathname),
      isNil(sessionToken)
    ])
  })

  const { data: userAppType } = useGetUserAppTypeQuery(null, {
    skip: any(equals(true))([
      onPublicRoute(pathname),
      onAuthRoute(pathname),
      isNil(sessionToken)
    ])
  })

  useEffect(() => {
    if (isFetching) {
      dispatch(setUserData({ isFetching }))
    }
  }, [dispatch, isFetching])

  useEffect(() => {
    if (not(isNil(data))) {
      dispatch(setUserData({ ...data, isSuccess }))
    }
  }, [data, dispatch, isSuccess])

  useEffect(() => {
    if (not(isNil(prop('id', data)))) {
      dispatch(updateUserData({ name: 'has_been_shown_a_modal', value: false }))
    }
  }, [data, dispatch, isSuccess])

  useEffect(() => {
    if (not(isNil(interestedSubdomains))) {
      dispatch(setUserData({ interestedSubdomains }))
    }
  }, [interestedSubdomains, dispatch])

  useEffect(() => {
    if (not(isNil(publicUser))) {
      dispatch(setUserData({ publicUser }))
    }
  }, [publicUser, dispatch])

  useEffect(() => {
    if (not(isNil(profileAnswers))) {
      dispatch(setUserData({ profileAnswers }))
    }
  }, [profileAnswers, dispatch])

  useEffect(() => {
    if (not(isNil(userAppType))) {
      dispatch(setUserData({ ...userAppType }))
    }
  }, [userAppType, dispatch])

  return
}

export default UserLoader
