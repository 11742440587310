import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const schoolSlice = createSlice({
  initialState,
  name: 'school',
  reducers: {
    setSchool: (state, { payload }) => payload
  }
})

export const { setSchool } = schoolSlice.actions

export default schoolSlice.reducer
