/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  useMediaQuery
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { all, and, any, equals, isNil, not, path, prop } from 'ramda'
import useAnalytics from 'hooks/useAnalytics'
import { useDispatch, useSelector } from 'react-redux'
import {
  setIsLoggedOutOpen,
  setIsSidebarOpen
} from 'components/sidebar/sidebarSlice'

import CrisisButton from 'components/buttons/CrisisButton'
import LogInButton from 'components/buttons/LogInButton'
import Icon from 'components/icon/Icon'
import SkipButton from './SkipButton'

const Header = () => {
  const dispatch = useDispatch()
  const { logoImageDarkMode, logoImageLightMode } = useSelector(prop('school'))
  const { id, isFetching, isSuccess } = useSelector(prop('user'))
  const { isOpen, isLoggedOutOpen } = useSelector(prop('sidebar'))
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()

  const isLoggedIn = not(isNil(id))
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('laptop'))
  const mode = useSelector(path(['theme', 'mode']))

  const checkDisplay = () => {
    if (and(isLoggedIn, not(equals(pathname, '/')))) return 'none'
    return 'flex'
  }

  // Dont display
  if (
    any(equals(true))([
      pathname.includes('feedback'),
      pathname.includes('accessibility'),
      pathname.includes('privacy'),
      pathname.includes('terms'),
      pathname.includes('logout'),
      isNil(isDesktop),
      isFetching,
      all(equals(true))([isDesktop, isLoggedIn, isSuccess])
    ])
  ) {
    return null
  }

  const handleClick = () => {
    if (isLoggedIn) return dispatch(setIsSidebarOpen(not(isOpen)))
    dispatch(setIsLoggedOutOpen(not(isLoggedOutOpen)))
  }

  return (
    <Stack
      component="header"
      sx={{
        alignItems: 'center',
        backgroundColor: 'surface.base',
        m: '0 auto',
        position: 'sticky',
        pt: { mobile: 0, laptop: 3 },
        px: { mobile: 0, laptop: 3 },
        top: 0,
        width: 1,
        zIndex: 5
      }}
    >
      <AppBar
        sx={{
          bgcolor: { mobile: 'surface.container', laptop: 'surface.low' },
          borderRadius: { mobile: 0, laptop: 6 },
          boxShadow: {
            mobile: 0,
            laptop:
              '0px 0px 16px 0px rgba(0, 0, 0, 0.08), 0px 4px 8px 1px rgba(0, 0, 0, 0.08)'
          },
          position: 'relative',
          width: 1
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            px: { mobile: 1.5, laptop: 3 },
            py: { mobile: 1, laptop: 3.5 }
          }}
        >
          <SkipButton />
          <IconButton
            aria-haspopup="menu"
            aria-label="Open Sidebar Navigation Menu"
            onClick={() => handleClick()}
            sx={{ display: { mobile: 'inline-flex', laptop: 'none' } }}
          >
            <Icon>menu</Icon>
          </IconButton>

          <Box
            aria-label="home"
            component={Button}
            LinkComponent={Link}
            sx={{
              background: `url(${equals('dark', mode) ? logoImageDarkMode : logoImageLightMode})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: 36,
              maxWidth: { mobile: 180, tablet: 325, desktop: 375 },
              p: 0,
              width: 1
            }}
            to={isLoggedIn ? '/home' : '/'}
            variant=""
          />

          <Box
            sx={{
              alignItems: 'center',
              display: { mobile: 'none', laptop: checkDisplay() },
              gridGap: 16
            }}
          >
            <CrisisButton />
            <LogInButton>
              <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
            </LogInButton>
            <Button
              LinkComponent={Link}
              onClick={() =>
                sendEvent('block_interactions', {
                  block_title: 'Logged Out Header',
                  click_element: 'Link',
                  element_title: 'Sign Up',
                  path: window.location.pathname
                })
              }
              to="/sign-up"
            >
              <FormattedMessage defaultMessage="Sign Up" id="39AHJm" />
            </Button>
          </Box>
          <IconButton
            aria-label="Go to Search"
            LinkComponent={Link}
            onClick={() => setIsSidebarOpen(false)}
            sx={{
              alignSelf: 'flex-end',
              display: { mobile: isLoggedIn ? 'flex' : 'none', laptop: 'none' }
            }}
            to="/search"
          >
            <Icon>search</Icon>
          </IconButton>
        </Toolbar>
      </AppBar>
    </Stack>
  )
}

export default Header
