/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import { isNil, prop, equals, and, not, or } from 'ramda'
import { Divider, Stack, Typography } from '@mui/material'
import { useGetOriginalProfileQuestionsQuery } from 'api/profileApi'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import ProfileQuestions from './ProfileQuestions'
import WhyWeAskForInfoDisclaimer from './WhyWeAskForInfoDisclaimer'
import ProfileAvatar from './ProfileAvatar'
import SubdomainSelector from 'components/subdomain-selector/SubdomainSelector'
import LocationSelect from 'components/location/LocationSelect'
import Icon from 'components/icon/Icon'

const QuestionsPage = () => {
  const { location_noun, show_locations_in_profile } = useSelector(
    prop('school')
  )
  const { id, profileAnswers, publicUser } = useSelector(prop('user'))

  const { data } = useGetOriginalProfileQuestionsQuery(null, {
    refetchOnMountOrArgChange: true
  })

  const profileQuestions = useMemo(() => {
    if (isNil(data)) return []
    return prop('profile_questions', data)
  }, [data])

  const hardWiredQuestions = useMemo(() => {
    if (isNil(data)) return []
    return prop('hardwired_profile_questions', data)
  }, [data])

  const getHardwiredProfileQuestions = questionNamesArray =>
    hardWiredQuestions
      .filter(({ name }) => questionNamesArray.includes(name))
      .map(({ name, ...rest }) => ({
        control_type: equals(name, 'birthdate') ? 'date' : 'text',
        ...rest
      }))

  const lookupAnswerForHardwiredQuestion = name => {
    if (isNil(publicUser)) return null
    if (equals('preferred_name', name))
      return prop('preferred_name', publicUser)
    return prop('birthdate', publicUser)
  }

  const getHardwiredProfileAnswers = questionNamesArray => {
    // Mimic the userAnswers data format with data pulled from public user
    return questionNamesArray.map(qName => {
      const question = hardWiredQuestions.find(({ name }) =>
        equals(name, qName)
      )
      return {
        answers: [lookupAnswerForHardwiredQuestion(qName)],
        profile_question_id: prop('id', question)
      }
    })
  }

  return (
    <Stack
      spacing={3}
      sx={{
        alignItems: 'center',
        pb: 4,
        px: { mobile: 2, tablet: 3 },
        width: 1
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
          alignSelf: 'flex-start',
          px: 2,
          py: 1.5
        }}
      >
        <Icon fill>lock</Icon>
        <Typography variant="subtitle1">
          <FormattedMessage
            defaultMessage="Your profile is 100% confidential."
            id="AY4AcY"
          />
        </Typography>
      </Stack>
      <Stack alignItems="center" spacing={3} sx={{ width: 1 }}>
        <Stack spacing={3} sx={{ maxWidth: 481, width: 1 }}>
          <ProfileAvatar />
          <Typography variant="subtitle4">User ID: {id}</Typography>
          <ProfileQuestions
            questions={getHardwiredProfileQuestions(['preferred_name'])}
            userAnswers={getHardwiredProfileAnswers(['preferred_name'])}
          />
          {show_locations_in_profile && (
            <LocationSelect title={location_noun} />
          )}
          <ProfileQuestions
            questions={profileQuestions.filter(({ control_type }) =>
              and(
                not(equals(control_type, 'hardwired')),
                equals(control_type, 'dropdown-menu')
              )
            )}
            userAnswers={profileAnswers}
          />
          <ProfileQuestions
            questions={profileQuestions.filter(({ control_type }) =>
              and(
                not(equals(control_type, 'hardwired')),
                equals(control_type, 'select-single')
              )
            )}
            userAnswers={profileAnswers}
          />
          <WhyWeAskForInfoDisclaimer />
          <ProfileQuestions
            questions={profileQuestions.filter(({ control_type }) =>
              and(
                not(equals(control_type, 'hardwired')),
                or(
                  equals(control_type, 'select-single'),
                  equals(control_type, 'radio-single')
                )
              )
            )}
            userAnswers={profileAnswers}
          />
          <ProfileQuestions
            questions={profileQuestions.filter(({ control_type }) =>
              and(
                not(equals(control_type, 'hardwired')),
                or(
                  equals(control_type, 'select-multi'),
                  equals(control_type, 'radio-multi')
                )
              )
            )}
            userAnswers={profileAnswers}
          />
        </Stack>
        <Divider />
        <Stack spacing={3} sx={{ maxWidth: 481, width: 1 }}>
          <Typography variant="h4">
            <FormattedMessage
              defaultMessage="Want a more personalized experience?"
              id="tZPD8F"
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              defaultMessage="Select any identities that are relevant to you."
              id="lw317K"
            />
          </Typography>
          <Stack
            direction="row"
            sx={{
              flexWrap: 'wrap',
              gap: 2
            }}
          >
            <ProfileQuestions
              questions={profileQuestions.filter(({ control_type }) =>
                and(
                  not(equals(control_type, 'hardwired')),
                  equals(control_type, 'yes-no-button')
                )
              )}
              userAnswers={profileAnswers}
            />
          </Stack>
          <WhyWeAskForInfoDisclaimer />
        </Stack>
        <Divider />
      </Stack>
      <Stack alignItems="center" spacing={4}>
        <Stack alignItems="center" spacing={1}>
          <Typography variant="h4">What are your priorities?</Typography>
          <Typography variant="body2">Select all that apply.</Typography>
        </Stack>
        <SubdomainSelector />
      </Stack>
    </Stack>
  )
}

export default QuestionsPage
