import { Button, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { equals, isEmpty, prop } from 'ramda'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { iconType } from 'utils/helpers'

const RelatedTopics = ({ selectedSubdomains = [] }) => {
  const { userDomains } = useSelector(prop('user'))

  const relatedSubdomains = useMemo(() => {
    const subdomains = userDomains.reduce(
      (subdomains, domain) => domain.subdomains.concat(subdomains),
      []
    )
    return subdomains.filter(({ id }) => selectedSubdomains.some(equals(id)))
  }, [selectedSubdomains, userDomains])

  if (isEmpty(selectedSubdomains)) return

  return (
    <Stack
      spacing={2}
      sx={{
        alignSelf: 'center',
        bgcolor: 'surface.container',
        borderRadius: 4,
        maxWidth: 1012,
        p: 2,
        width: 1
      }}
    >
      <Typography variant="subtitle1">Related Topics</Typography>
      <Stack
        direction="row"
        sx={{ alignItems: 'center', flexWrap: 'wrap', gap: 1 }}
      >
        {relatedSubdomains.map(({ link, section_id, title }) => (
          <Button
            key={title}
            LinkComponent={Link}
            startIcon={iconType(section_id)}
            to={link}
            variant="tonal"
          >
            {title}
          </Button>
        ))}
      </Stack>
    </Stack>
  )
}

RelatedTopics.propTypes = {
  selectedSubdomains: PropTypes.arrayOf(PropTypes.number)
}

export default RelatedTopics
