/* eslint-disable sort-keys */
import { Button, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { and, isEmpty, isNil, not, prop, slice } from 'ramda'
import { Link } from 'react-router-dom'
import { useAnalytics } from 'hooks'

import CardAnimationWrapper from 'components/cards/CardAnimationWrapper'
import InProgressGoalCard from 'components/cards/InProgressGoalCard'
import Icon from 'components/icon/Icon'

const Goals = ({ goals }) => {
  const { sendEvent } = useAnalytics()

  const handleLinkClick = title =>
    sendEvent('Stats_bar_goals', {
      'Element Title': title
    })

  return and(not(isNil(goals)), not(isEmpty(goals))) ? (
    <Stack spacing={1.5}>
      <Button
        endIcon={<Icon>arrow_forward</Icon>}
        LinkComponent={Link}
        onClick={() => handleLinkClick('Your Goals')}
        sx={{
          alignSelf: 'flex-end',
          display: { mobile: 'none', laptop: 'inline-flex' }
        }}
        to="/goals?active=my-goals"
        variant="text"
      >
        Your Goals
      </Button>
      <Stack
        direction="row"
        sx={{
          '& > div > div[class*=MuiCard-root]': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between'
          },
          alignItems: 'stretch',
          flexWrap: 'wrap',
          gap: 3,
          justifyContent: 'center'
        }}
      >
        {slice(0, 3, goals).map(goal => (
          <CardAnimationWrapper key={prop('id', goal)}>
            <InProgressGoalCard card={goal} />
          </CardAnimationWrapper>
        ))}
      </Stack>
      <Button
        endIcon={<Icon>arrow_forward</Icon>}
        LinkComponent={Link}
        onClick={() => handleLinkClick('Your Goals')}
        sx={{
          alignSelf: 'center',
          display: { mobile: 'inline-flex', laptop: 'none' }
        }}
        to="/goals?active=my-goals"
        variant="text"
      >
        Your Goals
      </Button>
    </Stack>
  ) : (
    <Stack alignItems="center" spacing={3}>
      <Typography variant="overline">upcoming goals</Typography>
      <Typography variant="body1">
        You don’t have any active goals right now.
      </Typography>
      <Button
        endIcon={<Icon>arrow_forward</Icon>}
        LinkComponent={Link}
        onClick={() => handleLinkClick('Browse Goals')}
        to="/goals?active=explore"
      >
        Browse Goals
      </Button>
      <Button
        LinkComponent={Link}
        onClick={() => handleLinkClick('Create a New Goal')}
        startIcon={<Icon>add</Icon>}
        to="/goals/create/new"
        variant="text"
      >
        Create A New Goal
      </Button>
    </Stack>
  )
}

Goals.propTypes = {
  goals: PropTypes.arrayOf(PropTypes.shape({}))
}

export default Goals
