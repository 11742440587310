import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material'
import { useAnalytics } from 'hooks'
import PropTypes from 'prop-types'
import { equals, includes, path, prop } from 'ramda'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { internalUrl, TYPE_SKILL } from 'components/cards/helpers'
import { useSelector } from 'react-redux'
import CardType from './CardType'
import { domainColor, truncateLines } from 'utils/helpers'
import { TYPE_FACT_TIP } from 'utils/card-functions'
import { useMemo } from 'react'
import { convertCardTypeToString } from 'utils/convertCardTypeToString'

const SmallCard = ({ blockTitle = '', card = null, isFeatured = false }) => {
  // Utils
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()
  const { id, name } = useSelector(prop('school'))

  // Variables
  const title = prop('title', card)
  const type = prop('type', card)
  const videoDescription = prop('video_description', card)
  const campusResource = prop('campus_resource', card)
  const domainId = prop('section_id', card)

  const photo = useMemo(() => {
    if (equals(TYPE_FACT_TIP, type)) return '/vectors/fact-tip-card-bg.svg'
    return path(['photo', 'large_url'], card) ?? prop('video_thumb_url', card)
  }, [card, type])

  const params = useMemo(
    () => ({
      'App Type': 'school',
      'Campus Resource': prop('campus_resource', card),
      'Card Domain': prop('domain_name', card),
      'Card Id': prop('id', card),
      'Card Name': prop('title', card).replace('"', ''),
      'Card Type': convertCardTypeToString(prop('type', card)),
      Path: pathname,
      'School Id': id,
      'School Name': name
    }),
    [card, pathname, id, name]
  )

  const handleClick = () => {
    if (isFeatured) return sendEvent('Featured_Card_Click', { ...params })
    return sendEvent('Card_detail_view', { ...params })
  }

  return (
    <Card
      sx={{
        backgroundColor: includes('Self Check', blockTitle)
          ? 'surface.high'
          : 'surface.container',
        maxWidth: includes('Self Check', blockTitle) ? 268 : 320,
        minWidth: 295,
        width: {
          mobile: includes('Self Check', blockTitle) ? 268 : 320,
          mointor: includes('Self Check', blockTitle) ? 268 : 320
        }
      }}
    >
      <CardActionArea
        LinkComponent={Link}
        onClick={handleClick}
        sx={{ p: 1 }}
        to={internalUrl(card)}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: '78px 1fr'
          }}
        >
          <Box
            sx={{
              backgroundColor: equals(TYPE_SKILL, type)
                ? domainColor(domainId)
                : 'transparent',
              backgroundImage: `url(${photo})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderRadius: 3,
              height: 78,
              width: 78
            }}
          />
          <Stack spacing={1}>
            <CardType
              campusResource={campusResource}
              type={type}
              videoDescription={videoDescription}
            />
            <Typography
              sx={{
                ...truncateLines(2)
              }}
              variant="h4"
            >
              {title}
            </Typography>
          </Stack>
        </Box>
      </CardActionArea>
    </Card>
  )
}

SmallCard.propTypes = {
  blockTitle: PropTypes.string,
  card: PropTypes.shape({}),
  isFeatured: PropTypes.bool
}

export default SmallCard
