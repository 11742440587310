export const MuiAccordionSummary = () => ({
  styleOverrides: {
    content: {
      margin: 0
    },
    root: {
      '&.Mui-expanded': {
        height: 69,
        minHeight: 0
      },
      borderRadius: 16,
      padding: '20px 16px'
    }
  }
})
