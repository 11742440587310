/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { Button, Stack, Typography } from '@mui/material'
import { useWindowResize } from 'hooks'
import { and, gt, isNil, lt, not, or } from 'ramda'

import Icon from 'components/icon/Icon'

const StatsBarButton = ({
  helperText,
  icon,
  isActive,
  number,
  onClick,
  selectedValue,
  title,
  value
}) => {
  // To handle the different break points
  // x is on window resize from the user (They must resize to execute)
  // windowWidth is if the user just landed on the page
  const { x } = useWindowResize()
  const windowWidth = window.innerWidth
  // Anything below 768px is mobile
  const isMobile = lt(or(x, windowWidth), 768)
  // Between laptop sizes is awkward with the sidebar that we have to account for
  const isBetween = and(
    gt(or(x, windowWidth), 1023),
    lt(or(x, windowWidth), 1085)
  )

  return (
    <Stack alignItems="center" direction="column">
      <Typography
        sx={{ display: or(isMobile, isBetween) ? 'block' : 'none' }}
        variant="body2"
      >
        {title}
      </Typography>
      <Button
        aria-controls={`stats-tab-${value}`}
        aria-selected={isActive}
        endIcon={
          <Icon
            sx={{
              transform: isActive ? 'rotate(-180deg)' : 'unset',
              transition: 'all .2s ease-in-out'
            }}
          >
            keyboard_arrow_down
          </Icon>
        }
        id={`stats-tab-${value}`}
        onClick={onClick}
        role="tab"
        selected={isActive}
        startIcon={
          <Icon
            fill
            sx={{
              fontSize: {
                mobile: '20px !important',
                tablet: '24px !important'
              }
            }}
          >
            {icon}
          </Icon>
        }
        tabIndex={or(isActive, isNil(selectedValue)) ? 0 : -1}
        variant="stats-bar"
      >
        {number}
        &nbsp;
        {and(not(isMobile), not(isBetween)) && helperText}
      </Button>
    </Stack>
  )
}

StatsBarButton.propTypes = {
  helperText: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedValue: PropTypes.number,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
}

export default StatsBarButton
