/* eslint-disable sort-keys */
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { and, compose, equals, gte, isNil, not, path, prop } from 'ramda'
import { useEffect, useState } from 'react'
import { useWindowResize } from 'hooks'
import CrisisButton from 'components/buttons/CrisisButton'
import Icon from 'components/icon/Icon'

const FlowHeader = () => {
  const { logoImageDarkMode, logoImageLightMode } = useSelector(prop('school'))
  const { publicUser, id } = useSelector(prop('user'))
  const { x } = useWindowResize()
  const isLoggedIn = not(isNil(id))
  const mode = useSelector(path(['theme', 'mode']))
  const avatar = prop('avatar', publicUser)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => setAnchorEl(null)

  useEffect(() => {
    if (and(gte(x, 899), not(isNil(anchorEl)))) setAnchorEl(null)
  }, [x, anchorEl])

  if (not(isLoggedIn)) return null

  return (
    <Stack
      sx={{
        lignItems: 'center',
        backgroundColor: 'surface.base',
        m: '0 auto',
        position: 'sticky',
        pt: { mobile: 1.5, laptop: 3 },
        px: { mobile: 1.5, laptop: 3 },
        top: 0,
        width: 1,
        zIndex: 5
      }}
    >
      <AppBar
        component="header"
        sx={{
          bgcolor: 'surface.low',
          borderRadius: 6,
          boxShadow: {
            mobile: 0,
            laptop:
              '0px 0px 16px 0px rgba(0, 0, 0, 0.08), 0px 4px 8px 1px rgba(0, 0, 0, 0.08)'
          },
          position: 'relative',
          width: 1
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            px: { mobile: 1.5, laptop: 3 },
            py: 2
          }}
        >
          <Box
            aria-label="home"
            component={Button}
            LinkComponent={Link}
            sx={{
              background: `url(${equals('dark', mode) ? logoImageDarkMode : logoImageLightMode})`,
              backgroundPosition: 'left center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: 36,
              // eslint-disable-next-line sort-keys
              maxWidth: 375,
              mr: 2,
              p: 0,
              width: 1
            }}
            to="/home"
            variant=""
          />
          <IconButton
            aria-haspopup="menu"
            aria-label="Open Sidebar Navigation Menu"
            onClick={compose(setAnchorEl, prop('currentTarget'))}
            sx={{ display: { mobile: 'inline-flex', tablet: 'none' } }}
          >
            <Icon>menu</Icon>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            keepMounted
            MenuListProps={{
              sx: {
                borderTop: 'none !important',
                p: '16px !important'
              }
            }}
            onClose={() => setAnchorEl(null)}
            open={Boolean(anchorEl)}
            PaperProps={{
              sx: {
                borderRadius: '16px !important'
              }
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <CrisisButton />
            <MenuItem
              aria-label="Profile"
              LinkComponent={Link}
              onClick={handleClose}
              to="/profile?active=about"
            >
              <Avatar src={avatar} sx={{ height: 24, mr: 1.5, width: 24 }} />
              <Typography>Profile</Typography>
            </MenuItem>
          </Menu>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              display: { mobile: 'none', tablet: 'flex' },
              gap: 2
            }}
          >
            <CrisisButton />
            <IconButton
              aria-label="Profile"
              LinkComponent={Link}
              to="/profile?active=about"
            >
              <Avatar src={avatar} />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
    </Stack>
  )
}

export default FlowHeader
