import { Chip, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { and, equals, isEmpty, isNil, not, or, path } from 'ramda'

import {
  TYPE_ASSESSMENT,
  TYPE_RESOURCE,
  TYPE_FACT_TIP,
  TYPE_LIFE_LESSON,
  TYPE_TOOL,
  TYPE_TIP,
  TYPE_SKILL
} from 'utils/card-functions'
import { useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'

const CardType = ({
  campusResource = false,
  type = null,
  videoDescription = null
}) => {
  const letters = useSelector(path(['school', 'letters']))

  const typeLabel = () => {
    if (
      or(
        equals(TYPE_LIFE_LESSON, type),
        and(equals(TYPE_RESOURCE, type), not(campusResource))
      )
    ) {
      return <Chip avatar={<Icon>import_contacts</Icon>} label="Expert Info" />
    }
    if (
      or(
        and(equals(TYPE_RESOURCE, type), campusResource),
        and(equals(TYPE_ASSESSMENT, type), campusResource)
      )
    )
      return (
        <Chip avatar={<Icon>location_on</Icon>} label={`${letters} Resource`} />
      )
    if (equals(TYPE_TOOL, type))
      return <Chip avatar={<Icon>build</Icon>} label="Tools" />
    if (equals(TYPE_SKILL, type))
      return <Chip avatar={<Icon>rocket</Icon>} label="Skill" />
    if (equals(TYPE_FACT_TIP, type))
      return <Chip avatar={<Icon>lightbulb</Icon>} label="Facts" />
    if (equals(TYPE_TIP, type))
      return <Chip avatar={<Icon>contract_edit</Icon>} label="Student Post" />
  }

  const contentType = () => {
    if (and(not(isNil(videoDescription)), not(isEmpty(videoDescription))))
      return <Chip avatar={<Icon>play_circle</Icon>} label="Video" />
    return null
  }

  return (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      {typeLabel()}
      {contentType()}
    </Stack>
  )
}

CardType.propTypes = {
  campusResource: PropTypes.bool,
  photoDescription: PropTypes.string,
  type: PropTypes.number,
  videoDescription: PropTypes.string
}

export default CardType
