import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography
} from '@mui/material'
import {
  append,
  equals,
  find,
  findIndex,
  gt,
  prop,
  propEq,
  without
} from 'ramda'
import { setCreatedGoals } from './onboardingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { subdomainsFromUserDomains } from 'utils/helpers'
import { useCreateGoalMutation, useDeleteGoalMutation } from 'api/goalsApi'
import { useAnalytics } from 'hooks'
import UnsetGoalContent from 'components/cards/UnsetGoalContent'

const GoalCard = ({ goal }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { sendEvent } = useAnalytics()

  const { createdGoals } = useSelector(prop('onboarding'))
  const { userDomains } = useSelector(prop('user'))

  const [createGoal] = useCreateGoalMutation()
  const [deleteGoal] = useDeleteGoalMutation()

  const { id, steps, title } = goal
  const isSelected = createdGoals.some(propEq(id, 'campus_goal_id'))
  const subdomainId = prop('subdomain_id', goal)
  const subdomains = subdomainsFromUserDomains(userDomains)
  const subdomain = find(propEq(subdomainId, 'id'))(subdomains)
  const unselectedImg = '/vectors/unselected-goal.svg'
  const selectedImg = '/vectors/selected-goal.svg'

  // Find our created goal for depth check
  const createdGoal = find(
    ({ campus_goal_id }) => equals(campus_goal_id, id),
    createdGoals
  )

  // On Creation
  // Async Fetch with RTK Query unwrap fn
  // this way we can use it once and not wait for success
  // prevention of using useEffect
  const handleGoalCreation = async () => {
    const goalBody = new FormData()
    for (const [key, value] of Object.entries(goal)) {
      if (equals(key, 'steps')) {
        prop('steps', goal).forEach((step, index) => {
          goalBody.append('steps[' + index + '][name]', step)
        })
      }
      if (equals(key, 'id')) {
        // when passing to goal creation endpoint, we need to convert id on goal
        // to campus_goal_id since the goal is a separate record in the db and
        // will generate its own id
        goalBody.append('campus_goal_id', value)
      } else {
        goalBody.append(key, value)
      }
    }
    goalBody.delete('steps')
    try {
      const data = await createGoal(goalBody).unwrap()
      dispatch(setCreatedGoals(append(data, createdGoals)))
      sendEvent('Goal_card_click', {
        'Element Title': 'Set Goal',
        'Goal Name': title,
        path: '/onboarding/goals'
      })
    } catch (err) {
      console.error('error creating goal', err)
    }
  }

  // On Deletion
  // Async Fetch with RTK Query unwrap fn
  // on success remove created goal from redux store
  const handleDeleteGoal = async () => {
    try {
      await deleteGoal(prop('id', createdGoal))
      dispatch(setCreatedGoals(without([createdGoal], createdGoals)))
      sendEvent('Goal_card_click', {
        'Element Title': 'Unset Goal',
        'Goal Name': title,
        path: '/onboarding/goals'
      })
    } catch (err) {
      console.error('error deleting goal', err)
    }
  }

  const handleSelection = () => {
    const found = findIndex(propEq(id, 'campus_goal_id'), createdGoals)
    if (gt(found, -1)) {
      handleDeleteGoal()
      return
    }
    handleGoalCreation()
  }

  return (
    <Card>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 1,
          justifyContent: 'space-between'
        }}
      >
        <Stack spacing={1}>
          <Typography variant="overline">{prop('title', subdomain)}</Typography>
          <Typography variant="h4">{title}</Typography>
          <UnsetGoalContent
            id={subdomainId}
            img={isSelected ? selectedImg : unselectedImg}
            isOnboarding
            steps={steps}
            title={title}
            useImagePath
          />
        </Stack>
        <CardActions variant="center">
          <Button
            aria-label={
              isSelected
                ? intl.formatMessage({ defaultMessage: 'saved', id: 'dxFzER' })
                : intl.formatMessage({
                    defaultMessage: 'not saved',
                    id: '80shc5'
                  })
            }
            fullWidth
            onClick={handleSelection}
          >
            {isSelected ? (
              <FormattedMessage defaultMessage="Saved" id="fsB/4p" />
            ) : (
              <FormattedMessage defaultMessage="Set Goal" id="nhE4Wm" />
            )}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  )
}

GoalCard.propTypes = {
  goal: PropTypes.shape({
    id: PropTypes.number,
    participants: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    steps: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string
  }).isRequired
}

export default GoalCard
