/* eslint-disable sort-keys */
import { Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { not, path, prop } from 'ramda'

import Hero from './components/Hero'
import Priorities from './components/priorities/Priorities'
import PrioritiesModal from './components/priorities/Modal'
import Featured from './components/Featured'
import CheckIn from './components/check-in/CheckIn'
import ActionButton from './components/ActionButtton'
import SpotlightWrapper from 'components/spotlight/SpotlightWrapper'

const Dashboard = () => {
  const isPrioritiesModalOpen = useSelector(
    path(['priorities', 'isPrioritiesModalOpen'])
  )
  const { hide_resources } = useSelector(prop('school'))

  return (
    <Stack
      spacing={{ mobile: 4, laptop: 7 }}
      sx={{
        alignItems: 'center',
        m: '0 auto',
        maxWidth: 1400,
        px: { mobile: 2, laptop: 4 },
        width: 1
      }}
    >
      {isPrioritiesModalOpen && <PrioritiesModal />}
      <SpotlightWrapper
        needsPadding
        placement={{ mobile: 'bottom-start', laptop: 'bottom-start' }}
        spotlightType="introduction"
        step={not(hide_resources) ? 3 : 2}
      >
        <Hero />
      </SpotlightWrapper>
      <SpotlightWrapper
        needsPadding
        placement={{ mobile: 'top-start', laptop: 'top-start' }}
        spotlightType="introduction"
        step={not(hide_resources) ? 4 : 3}
      >
        <Priorities />
      </SpotlightWrapper>
      <SpotlightWrapper
        needsPadding
        placement={{ mobile: 'top-start', laptop: 'top-start' }}
        spotlightType="introduction"
        step={not(hide_resources) ? 5 : 4}
      >
        <CheckIn />
      </SpotlightWrapper>
      <Featured />
      <ActionButton />
    </Stack>
  )
}

export default Dashboard
