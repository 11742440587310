import { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import {
  defaultTo,
  equals,
  filter,
  gt,
  isEmpty,
  isNil,
  length,
  not,
  path,
  prop
} from 'ramda'
import { useGetSelfCheckQuizDataQuery } from 'api/statsApi'
import { useGetGoalsQuery } from 'api/goalsApi'
import { useGetFavoritesQuery } from 'api/userApi'
import { useSelector } from 'react-redux'

import StatsBarButton from './StatsBarButton'
import SelfChecks from './SelfChecks'
import Goals from './Goals'
import Resources from './Resources'
import ContentPanel from './ContentPanel'

const StatsBar = () => {
  const [isSelected, setIsSelected] = useState(null)

  const isSecondaryUser = useSelector(path(['user', 'is_secondary_user']))
  const { domain } = useSelector(prop('school'))

  // Self Checks
  const { data: selfChecks = [], isFetching = true } =
    useGetSelfCheckQuizDataQuery(null, { skip: isNil(domain) })

  const totalCompleted = isFetching
    ? 0
    : length(
        filter(
          ({ last_completed_at }) => not(isNil(last_completed_at)),
          selfChecks
        )
      )

  // Goals
  const { data: goals } = useGetGoalsQuery(null, { skip: isNil(domain) })

  // Resources
  const { data: resources } = useGetFavoritesQuery(null, {
    skip: isNil(domain)
  })

  const handleClick = val => {
    if (equals(isSelected, val)) return setIsSelected(null)
    return setIsSelected(val)
  }

  const handlekeyDown = ({ key }) => {
    if (isEmpty(isSelected)) return
    if (equals(key, 'ArrowLeft')) {
      setIsSelected(prev => (prev - 1 + 3) % 3)
    }
    if (equals(key, 'ArrowRight')) {
      setIsSelected(prev => (prev + 1) % 3)
    }
  }

  useEffect(() => {
    const currentTab = document.getElementById(`stats-tab-${isSelected}`)
    if (currentTab) {
      currentTab.focus()
    }
  }, [isSelected])

  return (
    <Stack alignItems="center" spacing={4.5}>
      <Stack
        direction="row"
        onKeyDown={handlekeyDown}
        role="tablist"
        spacing={3}
        sx={{
          justifyContent: 'center',
          minHeight: 'unset',
          width: 1
        }}
      >
        <StatsBarButton
          helperText="Self Checks Taken"
          icon="spa"
          isActive={equals(isSelected, 0)}
          number={`${totalCompleted}/3`}
          onClick={() => handleClick(0)}
          selectedValue={isSelected}
          title="Self-Checks"
          value={0}
        />
        {not(isSecondaryUser) && (
          <StatsBarButton
            helperText={`${gt(length(goals), 1) ? 'Goals' : 'Goal'} in Progress`}
            icon="mountain_flag"
            isActive={equals(isSelected, 1)}
            number={defaultTo(0, length(goals))}
            onClick={() => handleClick(1)}
            selectedValue={isSelected}
            title="Goals"
            value={1}
          />
        )}
        <StatsBarButton
          helperText="Saved Resources"
          icon="bookmark"
          isActive={equals(isSelected, 2)}
          number={defaultTo(0, length(resources))}
          onClick={() => handleClick(2)}
          selectedValue={isSelected}
          title="Favorites"
          value={2}
        />
      </Stack>
      <ContentPanel isActive={equals(isSelected, 0)} value={0}>
        <SelfChecks selfChecks={selfChecks} />
      </ContentPanel>
      <ContentPanel isActive={equals(isSelected, 1)} value={1}>
        <Goals goals={goals} />
      </ContentPanel>
      <ContentPanel isActive={equals(isSelected, 2)} value={2}>
        <Resources resources={resources} />
      </ContentPanel>
    </Stack>
  )
}

export default StatsBar
