/* eslint-disable sort-keys */
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { equals, prop, toLower } from 'ramda'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { domainColor } from 'utils/helpers'

const Hero = ({ imageUrl }) => {
  const { domainName } = useParams()
  const { userDomains } = useSelector(prop('user'))

  const domainId = useMemo(() => {
    return prop(
      'id',
      userDomains.find(({ title }) => equals(toLower(title), domainName))
    )
  }, [domainName, userDomains])

  return (
    <Box
      data-testid="banner"
      sx={{
        backgroundImage: `url('data:image/svg+xml;utf8,<svg fill="none" height="285px" viewBox="0 0 1139 285" width="100%" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H1141V285C1141 285 931.429 231.226 570.5 231.226C209.571 231.226 0 285 0 285V0Z" fill="${encodeURIComponent(domainColor(domainId))}"/></svg>')`,
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: { mobile: 184, tablet: 383 },
        position: 'relative',
        width: 1
      }}
    >
      <Box
        sx={{
          background: `url(${imageUrl})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          bottom: 0,
          height: { mobile: 147, tablet: 289 },
          left: {
            mobile: 'calc(50% - 156px)',
            tablet: 'calc(50% - 252px)',
            laptop: 'calc(50% - 305px)'
          },
          maxWidth: { mobile: 312, tablet: 504, laptop: 610 },
          position: 'absolute',
          width: 1
        }}
      />
    </Box>
  )
}

Hero.propTypes = {
  imageUrl: PropTypes.string.isRequired
}

export default Hero
