import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import {
  and,
  compose,
  defaultTo,
  equals,
  gt,
  isNil,
  length,
  lt,
  not,
  or,
  path,
  prop
} from 'ramda'
import { useResetPasswordMutation } from 'api/authApi'
import { useLocalStorage } from 'hooks'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Icon from 'components/icon/Icon'
import Toast from 'components/toast/Toast'

const PasswordReset = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const { clear } = useLocalStorage()
  const [search] = useSearchParams()
  const email = search.get('email')

  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState(null)
  const [showRevalidation, setShowRevalidation] = useState(false)
  const [revalidation, setRevalidation] = useState(null)
  const [error, setError] = useState(null)

  const [resetPassword] = useResetPasswordMutation()

  const passwordResetSubmit = async () => {
    const body = new FormData()
    body.append('token', token)
    body.append('email', email)
    body.append('password', password)
    body.append('password_confirmation', revalidation)
    clear()
    try {
      const data = await resetPassword(body).unwrap()
      const hasSuccess = equals(true, prop('success', data))
      const hasError = equals(false, prop('success', data))
      if (hasSuccess) {
        navigate('/sign-in')
      }
      if (hasError) {
        setError('An error occurred, please try again later')
      }
    } catch (err) {
      console.warn('Error resetting password:', err)
      setError('An error occurred, please try again later')
    }
  }

  return (
    <Stack
      spacing={1}
      sx={{
        alignItems: 'center',
        height: 'calc(100vh - 220px)',
        justifyContent: 'center',
        width: 1
      }}
    >
      <Typography fontSize="22" textAlign="center" variant="h1">
        <FormattedMessage defaultMessage="Reset Your Password" id="ORdEzh" />
      </Typography>
      <Stack
        component="form"
        onSubmit={e => {
          e.preventDefault()
          passwordResetSubmit()
        }}
        spacing={2}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 375,
          width: 1
        }}
      >
        <Typography component="label" htmlFor="password" variant="body2">
          <FormattedMessage
            defaultMessage="Create your new password below."
            id="y3LhSp"
          />
        </Typography>
        <TextField
          autoComplete="off"
          error={and(
            not(isNil(password)),
            or(lt(length(password), 8), gt(length(password), 13))
          )}
          fullWidth
          helperText={
            and(
              not(isNil(password)),
              or(lt(length(password), 8), gt(length(password), 13))
            ) ? (
              <FormattedMessage
                defaultMessage="Must be between 8 and 13 characters long"
                id="Ao8ysG"
              />
            ) : (
              <FormattedMessage defaultMessage="8-13 characters" id="lco3nU" />
            )
          }
          id="password"
          inputProps={{
            autoComplete: 'off'
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={() => setShowPassword(not(showPassword))}>
                  {showPassword ? (
                    <Icon>visibility_off</Icon>
                  ) : (
                    <Icon>visibility</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          label={<FormattedMessage defaultMessage="Password" id="5sg7KC" />}
          onChange={compose(setPassword, path(['target', 'value']))}
          required
          sx={{
            my: 1
          }}
          type={showPassword ? 'text' : 'password'}
          value={defaultTo('', password)}
        />

        <TextField
          error={and(
            not(isNil(revalidation)),
            not(equals(password, revalidation))
          )}
          fullWidth
          helperText={
            and(
              not(isNil(revalidation)),
              not(equals(password, revalidation))
            ) && (
              <FormattedMessage
                defaultMessage="Make sure your passwords match"
                id="2iOMhc"
              />
            )
          }
          id="re-validate"
          inputProps={{
            autoComplete: 'off'
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={() => setShowRevalidation(not(showRevalidation))}
                >
                  {showRevalidation ? (
                    <Icon>visibility_off</Icon>
                  ) : (
                    <Icon>visibility</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          label={
            <FormattedMessage defaultMessage="Re-enter Password" id="qxYRGU" />
          }
          onChange={compose(setRevalidation, path(['target', 'value']))}
          required
          type={showRevalidation ? 'text' : 'password'}
          value={defaultTo('', revalidation)}
        />

        <Toast isShown={not(isNil(error))} message={error} type="error" />
        <Button fullWidth type="submit">
          <FormattedMessage defaultMessage="Submit" id="wSZR47" />
        </Button>
      </Stack>
    </Stack>
  )
}

export default PasswordReset
