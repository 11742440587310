/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import { useMemo } from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import {
  equals,
  prop,
  length,
  reject,
  isEmpty,
  map,
  isNil,
  sortBy,
  path,
  reverse
} from 'ramda'
import { useGetFavoritesQuery } from 'api/userApi'
import { useSelector } from 'react-redux'

import CardHandler from 'components/cards/CardHandler'
import CardGridWrapper from 'components/cards/CardGridWrapper'
// import FilterMenu from 'components/filter/FilterMenu'
import useBuildFilters from 'components/filter/useBuildFilters'

const Favorites = () => {
  const { domain } = useSelector(prop('school'))
  const { contentType } = useSelector(prop('filter'))
  const { contentBase, domainId } = useBuildFilters()

  const contentCheck = useMemo(() => {
    const arr = contentBase.filter(({ name }) =>
      contentType.some(type => equals(type, name))
    )
    const paramArr = map(prop('value'), arr)
    return paramArr
  }, [contentBase, contentType])

  const queryparams = useMemo(
    () => ({
      'filter[campus_resource]': contentCheck.includes(0) ? 1 : 0,
      'filter[domains]': domainId(),
      'filter[types]': reject(equals(0), contentCheck),
      org_domain: domain
    }),
    [contentCheck, domainId, domain]
  )

  const { data = [], isFetching } = useGetFavoritesQuery(queryparams, {
    skip: isNil(domain)
  })

  const cards = useMemo(() => {
    if (isEmpty(data)) return []
    return reverse(sortBy(path(['pivot', 'genesis']), data))
  }, [data])

  return (
    <Stack spacing={4.5} sx={{ pb: { mobile: 4, laptop: 8 } }}>
      <Box
        sx={{
          backgroundImage: 'url(/vectors/favorites-banner.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: 383,
          width: 1
        }}
      />
      <Stack
        spacing={4.5}
        sx={{
          alignSelf: 'center',
          maxWidth: 1008,
          px: { mobile: 2, tablet: 3 }
        }}
      >
        <Typography
          sx={{
            pl: { mobile: 1, tablet: 0 }
          }}
          variant="h1"
        >
          <FormattedMessage defaultMessage="Favorites" id="SMrXWc" />
        </Typography>
        <Stack alignItems="center" spacing={4}>
          <Typography
            sx={{ alignSelf: 'flex-start', pl: { mobile: 1, tablet: 0 } }}
          >
            {length(cards)} Saved
          </Typography>
          <Divider />
          {/* <FilterMenu hasContentFilter hasTopicFilter /> */}
          <CardGridWrapper isLoading={isFetching}>
            {cards.map(card => (
              <CardHandler card={card} key={prop('id', card)} />
            ))}
          </CardGridWrapper>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Favorites
