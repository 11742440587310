import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { all, and, equals, isNil, not, path, prop } from 'ramda'
import { onAuthRoute, onPublicRoute, privateRoutes } from 'utils/route-helpers'

const getSessionId = () => {
  const key = localStorage.getItem('@you:sessionId')
  if (not(isNil(key))) return JSON.parse(key)
  return ''
}

const getLocale = () => {
  const locale = localStorage.getItem('@MT:locale')
  if (not(isNil(locale))) {
    return locale
  }
  return 'en'
}

const HOST = process.env.REACT_APP_PUBLIC_YOU_API_HOST
const key = process.env.REACT_APP_PUBLIC_KEEN_POST_KEY

const baseQuery = fetchBaseQuery({
  baseUrl: `${HOST}`,
  prepareHeaders: headers => {
    headers.set('Accept', 'application/json')
    headers.set('X-Session-Id', getSessionId())
    headers.set('locale', getLocale())
    headers.set('keen-post-key', key)
    return headers
  }
})

const pathname = window.location.pathname
const onPrivateRoute = all(equals(true))([
  not(equals(pathname, '/')),
  not(onPublicRoute(pathname)),
  not(onAuthRoute(pathname)),
  privateRoutes.some(route => pathname.includes(route))
])

const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (
    and(prop('error', result), equals(path(['error', 'status'], result), 401))
  ) {
    // For testing
    // console.error(
    //   'something returned 401, should send back to sign in',
    //   prop('meta', result)
    // )
    // Clear Session Id
    localStorage.removeItem('@you:sessionId')
    // on a private route
    // redirect back to sign in page with redirect
    // This is for the odd times when the user returns nothing
    if (onPrivateRoute) {
      localStorage.setItem('redirect', `${pathname}${window.location.search}`)
    }
    // On 401 send user to sign in
    window.location.href = '/sign-in'
  }
  return result
}

export const api = createApi({
  baseQuery: baseQueryWithAuth,
  endpoints: () => ({}),
  reducerPath: 'api',
  tagTypes: [
    'new cards',
    'card',
    'cards',
    'campus goals',
    'completed goals',
    'goal',
    'goals',
    'profile questions',
    'pick up cards',
    'reality score',
    'panic card',
    'school',
    'scores',
    'score',
    'self check',
    'about',
    'favorites',
    'student content',
    'featured content',
    'featured',
    'about',
    'be stats',
    'role types',
    'subdomain',
    'card sentiment',
    'legalDoc',
    'public user',
    'referral links',
    'randomized cards',
    'user',
    'user app type',
    'user attributes',
    'user domains',
    'user profile answers',
    'user subdomains'
  ]
})
