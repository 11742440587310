export const MuiSwitch = palette => ({
  styleOverrides: {
    root: {
      '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          '& + .MuiSwitch-track': {
            backgroundColor: palette.surface.highest,
            border: 0,
            opacity: 1
          }
        },
        boxShadow: 'none',
        marginLeft: 4,
        marginTop: 4,
        padding: 0
      },
      '& .MuiSwitch-thumb': {
        '&::before': {
          content: '""',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%'
        },
        border: 'none',
        boxShadow: 'none',
        boxSizing: 'border-box',
        color: palette.surface.low,
        height: 24,
        width: 24
      },
      '& .MuiSwitch-track': {
        backgroundColor: palette.surface.highest,
        borderRadius: 100,
        opacity: 1
      },
      height: 32,
      padding: 0,
      width: 52
    }
  }
})
