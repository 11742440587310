/* eslint-disable sort-keys */
import { useEffect } from 'react'
import { Drawer, Toolbar, useMediaQuery } from '@mui/material'
import { equals, lt, any, path } from 'ramda'
import { useWindowResize } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setIsSidebarOpen } from './sidebarSlice'

import LoggedInSidebar from './LoggedInSidebar'

const Sidebar = () => {
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('laptop'))
  const isOpen = useSelector(path(['sidebar', 'isSidebarOpen']))

  const { x } = useWindowResize()
  const { pathname } = useLocation()

  // Close sidebar on window resize
  useEffect(() => {
    if (lt(x, 1023)) dispatch(setIsSidebarOpen(false))
  }, [dispatch, x])

  const anyCheck = [
    equals(pathname, '/'),
    pathname.includes('sign-in'),
    pathname.includes('sign-up'),
    pathname.includes('onboarding'),
    pathname.includes('accessibility'),
    pathname.includes('privacy'),
    pathname.includes('terms'),
    pathname.includes('feedback'),
    pathname.includes('public'),
    pathname.includes('verify'),
    pathname.includes('password-reset'),
    pathname.includes('referral'),
    pathname.includes('redirect'),
    pathname.includes('logout'),
    pathname.includes('destress'),
    pathname.includes('404'),
    pathname.includes('sso'),
    pathname.includes('email'),
    pathname.includes('self-checks/succeed'),
    pathname.includes('self-checks/thrive'),
    pathname.includes('self-checks/matter'),
    pathname.includes('create')
  ]

  if (any(equals(true))(anyCheck)) return null

  return (
    <Drawer
      anchor="left"
      component="aside"
      ModalProps={{
        keepMounted: true
      }}
      onClose={() => dispatch(setIsSidebarOpen(false))}
      open={isOpen}
      PaperProps={{
        component: 'nav',
        sx: {
          backgroundColor: 'surface.low',
          borderBottomRightRadius: { mobile: 16, laptop: 0 },
          borderTopRightRadius: { mobile: 16, laptop: 0 },
          width: 300,
          zIndex: 4
        }
      }}
      sx={{
        width: 300
      }}
      variant={isDesktop ? 'permanent' : 'temporary'}
    >
      <Toolbar
        disableGutters
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          height: 1,
          pb: { mobile: 0, laptop: 1 },
          pt: { mobile: 0.5, laptop: 3 }
        }}
      >
        <LoggedInSidebar />
      </Toolbar>
    </Drawer>
  )
}

export default Sidebar
