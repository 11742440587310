// This list comes from Phase 2 ticket:
// https://gitlab.com/grit-digital-health/YOU-Frontend/-/issues/2415

export const studentMappedSubdomain = [
  {
    name: 'Energized',
    subdomains: [4, 15]
  },
  {
    name: 'Tired',
    subdomains: [1, 114]
  },
  {
    name: 'Sick',
    subdomains: [1, 114]
  },
  {
    name: 'Social',
    subdomains: [17, 15]
  },
  {
    name: 'Thinking about my future',
    subdomains: [12, 14, 9, 20]
  },
  {
    name: 'Sad',
    subdomains: [7, 3, 18]
  },
  {
    name: 'Self-discovery',
    subdomains: [19, 16, 20]
  },
  {
    name: 'Mindful',
    subdomains: [18, 20]
  },
  {
    name: 'Overwhelmed',
    subdomains: [3, 18]
  },
  {
    name: 'Relaxed',
    subdomains: [18]
  },
  {
    name: 'Angry',
    subdomains: [2]
  },
  {
    name: 'Self-conscious',
    subdomains: [5, 19]
  },
  {
    name: 'Anxious',
    subdomains: [3]
  },
  {
    name: 'Partying too much',
    subdomains: [6, 8, 20]
  },
  {
    name: 'Flirty',
    subdomains: [8, 17]
  },
  {
    name: 'Lonely',
    subdomains: [17, 15, 7]
  },
  {
    name: 'Falling behind academically',
    subdomains: [10, 11]
  },
  {
    name: 'Stressed about money',
    subdomains: [113, 3, 12]
  }
]

export const staffMappedSubdomain = [
  {
    name: 'Energized',
    subdomains: [75, 84]
  },
  {
    name: 'Tired',
    subdomains: [74, 78]
  },
  {
    name: 'Sick',
    subdomains: [74, 78]
  },
  {
    name: 'Social',
    subdomains: [86, 83, 85]
  },
  {
    name: 'Thinking about my future',
    subdomains: [69, 70, 72]
  },
  {
    name: 'Sad',
    subdomains: [80, 77, 82]
  },
  {
    name: 'Self-discovery',
    subdomains: [87, 84]
  },
  {
    name: 'Mindful',
    subdomains: [82, 84, 73]
  },
  {
    name: 'Overwhelmed',
    subdomains: [77, 82]
  },
  {
    name: 'Relaxed',
    subdomains: [82]
  },
  {
    name: 'Overindulging',
    subdomains: [79, 69]
  },
  {
    name: 'Self-conscious',
    subdomains: [76, 87, 82]
  },
  {
    name: 'Anxious',
    subdomains: [77, 82]
  },
  {
    name: 'Lonely',
    subdomains: [86, 82]
  },
  {
    name: 'Stressed about money',
    subdomains: [71, 77]
  }
]
