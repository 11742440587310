import { FormattedMessage } from 'react-intl'
import { useAnalytics } from 'hooks'
import {
  useUpdateUserMutation,
  useUpdateUserQuestionsMutation
} from 'api/userApi'
import { not, equals, isNil, and, path, compose, prop, tap } from 'ramda'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import { updateUserData } from 'store/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'

const SettingsPage = () => {
  const dispatch = useDispatch()
  const {
    email_opt_in,
    id: userId,
    is_secondary_user,
    research_opted_in,
    user_type
  } = useSelector(prop('user'))
  const userType = equals(user_type, 'primary') ? 'Student' : 'Faculty/Staff'

  const { id: schoolId } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()

  const [updateUser] = useUpdateUserMutation()
  const [updateProfileQuestion] = useUpdateUserQuestionsMutation()

  const handleUpdateUser = value => {
    const newVal = equals(value, 'Student') ? 'primary' : 'secondary'
    const body = new FormData()
    body.append('user_type', newVal)
    dispatch(updateUserData({ name: 'user_type', newVal }))
    updateUser(body)
  }

  const handleUpdateQuestion = () =>
    updateProfileQuestion({
      answers: [equals('primary', user_type) ? 'primary' : 'secondary'],
      profile_question_id: 36
    })

  const handleEmailUpdate = () => {
    updateUser({ email_opt_in: not(email_opt_in) })
    dispatch(
      updateUserData({
        name: 'email_opt_in',
        value: not(email_opt_in)
      })
    )
    sendEvent('you_email_opt_in', {
      email_opt_in: not(email_opt_in),
      opt_in_element: 'Profile'
    })
  }

  const handleResearchUpdate = () => {
    updateUser({ research_opted_in: not(research_opted_in) })
    dispatch(
      updateUserData({
        name: 'research_opted_in',
        value: not(research_opted_in)
      })
    )
  }

  if (isNil(userId))
    return (
      <Stack data-testid="skeleton" spacing={4}>
        <Stack spacing={2}>
          <Skeleton height={26} variant="rectangular" width={212} />
          <Skeleton height={26} variant="rectangular" width={212} />
          <Skeleton height={26} variant="rectangular" width={212} />
        </Stack>
        <Stack spacing={2}>
          <Skeleton height={26} variant="rectangular" width={250} />
          <Skeleton height={26} variant="rectangular" width={300} />
          <Skeleton height={26} variant="rectangular" width={212} />
          <Skeleton height={26} variant="rectangular" width={300} />
          <Skeleton height={26} variant="rectangular" width={212} />
        </Stack>
      </Stack>
    )

  return (
    <Stack spacing={4} sx={{ m: '0 auto', maxWidth: 481 }}>
      <Stack spacing={2}>
        <Typography variant="h4">
          <FormattedMessage defaultMessage="Profile Type" id="somORZ" />
        </Typography>
        <FormControl fullWidth>
          <Select
            onChange={compose(
              handleUpdateQuestion,
              tap(handleUpdateUser),
              path(['target', 'value'])
            )}
            renderValue={opt => opt}
            value={userType}
          >
            <MenuItem value="Student">
              <ListItemText primary="Student" />
              {equals(user_type, 'primary') && <Icon>check</Icon>}
            </MenuItem>
            <MenuItem value="Faculty/Staff">
              <ListItemText primary="Faculty/Staff" />
              {equals(user_type, 'secondary') && <Icon>check</Icon>}
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="h4">
            <FormattedMessage
              defaultMessage="Notification Preferences"
              id="4tJqOL"
            />
          </Typography>

          <Typography variant="body1">
            <FormattedMessage
              defaultMessage="Occasional, personalized well-being tips va email (no spam)."
              id="o3/8nZ"
            />
          </Typography>
        </Stack>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={email_opt_in} onChange={handleEmailUpdate} />
            }
            label="Email. Occasional, personalized well-being tips (no spam)."
          />
          {and(equals(1, schoolId), not(is_secondary_user)) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={research_opted_in}
                  onChange={handleResearchUpdate}
                />
              }
              label="Research opportunities to help improve this site experience."
            />
          )}
        </FormGroup>
      </Stack>
    </Stack>
  )
}

export default SettingsPage
