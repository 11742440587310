import { api as baseQuery } from './baseQuery'

export const cardsApi = baseQuery.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    favorite: mutation({
      invalidatesTags: ['favorite', 'cards', 'card', 'pick up cards'],
      query: ({ isFavorite, card_id }) => ({
        body: { card_id },
        method: 'POST',
        url: `/favorite-${isFavorite ? 'add' : 'remove'}`
      })
    }),
    getCard: query({
      providesTags: ['card'],
      query: id => `/v2/cards/${id}`
    }),
    getCardSentiment: query({
      providesTags: ['card sentiment'],
      query: id => `/card-sentiment/${id}`
    }),
    getCards: query({
      providesTags: ['cards'],
      query: params => ({
        params,
        url: '/v4/cards'
      })
    }),
    // Params Accepted
    // org_domain: domainId of app type
    // is_staff_faculty: 1 or 0
    // location_id: location ID of the school for the user
    getFeaturedCards: query({
      providesTags: ['featured cards'],
      query: params => ({
        params,
        url: '/featured-cards'
      })
    }),
    getPickupCards: query({
      providesTags: ['pick up cards'],
      query: () => '/v2/cards/pick-up-where-you-left-off'
    }),
    // Params Accepted
    // filter[types]: list/array
    // subdomains: list/array
    // take: number (7 default without passing)
    getRandomizedCards: query({
      providesTags: ['randomized cards'],
      query: params => ({
        params,
        url: '/v4/random-cards'
      })
    }),
    setCardSentiment: mutation({
      invalidatesTags: ['card sentiment'],
      query: body => ({
        body,
        method: 'POST',
        url: '/card-sentiment'
      })
    })
  }),
  reducerPath: 'cardsApi'
})

export const {
  useFavoriteMutation,
  useFavoriteResourceMutation,
  useGetCardQuery,
  useGetCardSentimentQuery,
  useGetCardsQuery,
  useGetFeaturedCardsQuery,
  useGetPickupCardsQuery,
  useGetRandomizedCardsQuery,
  useSetCardSentimentMutation
} = cardsApi
