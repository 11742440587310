import { Card, CardContent } from '@mui/material'
import { useMemo, useState } from 'react'
import { defaultTo, find, isEmpty, isNil, or, prop, propEq } from 'ramda'
import { useSelector } from 'react-redux'
import { staffMappedSubdomain, studentMappedSubdomain } from './mappedSubdomain'
import { useGetRandomizedCardsQuery } from 'api/cardsApi'

import Heading from './Heading'
import SubdomainSelection from './SubdomainSelection'
import CardGrid from './CardGrid'
import RelatedTopics from './RelatedTopics'

const CheckIn = () => {
  const { is_secondary_user } = useSelector(prop('user'))

  const [selected, setSelected] = useState(null)

  const selectedSubdomains = useMemo(() => {
    if (is_secondary_user)
      return defaultTo(
        [],
        prop('subdomains', find(propEq(selected, 'name'), staffMappedSubdomain))
      )
    return defaultTo(
      [],
      prop('subdomains', find(propEq(selected, 'name'), studentMappedSubdomain))
    )
  }, [selected, is_secondary_user])

  const params = useMemo(
    () => ({
      'filter[types]': [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13],
      subdomains: selectedSubdomains
    }),
    [selectedSubdomains]
  )

  const { data = [], refetch } = useGetRandomizedCardsQuery(params, {
    skip: or(isNil(selected), isEmpty(selectedSubdomains))
  })

  return (
    <Card
      sx={{
        backgroundColor: 'surface.low',
        width: 1
      }}
    >
      <Heading />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4
        }}
      >
        <SubdomainSelection
          refetch={refetch}
          selected={selected}
          setSelected={setSelected}
        />
        <CardGrid cards={data} selected={selected} />
        <RelatedTopics selectedSubdomains={selectedSubdomains} />
      </CardContent>
    </Card>
  )
}

export default CheckIn
