import { Avatar, Button, FormControlLabel, Stack, Switch } from '@mui/material'
import { equals, path } from 'ramda'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { ThemeContext } from 'theme/MuiThemeProvider'
import { useTheme } from '@emotion/react'

const style = color => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 18 18"><path fill="${encodeURIComponent(
          color
        )}" d="M9.02884 17.5002C6.65963 17.5002 4.65066 16.6758 3.00194 15.0271C1.35323 13.3784 0.52887 11.3694 0.52887 9.00018C0.52887 7.02713 1.13944 5.2733 2.36057 3.7387C3.5817 2.20409 5.20829 1.21498 7.24032 0.771379C7.64417 0.670096 7.95795 0.768811 8.18167 1.06753C8.40539 1.36624 8.42429 1.69445 8.23839 2.05213C8.03199 2.42776 7.87879 2.818 7.77879 3.22285C7.67879 3.62769 7.62879 4.05346 7.62879 4.50018C7.62879 6.13908 8.20241 7.53215 9.34964 8.67938C10.4969 9.8266 11.8899 10.4002 13.5288 10.4002C14.0199 10.4002 14.4891 10.338 14.9365 10.2137C15.384 10.0893 15.7756 9.9451 16.1115 9.781C16.4295 9.63742 16.7237 9.66018 16.9942 9.84928C17.2647 10.0384 17.3619 10.3254 17.2859 10.7104C16.9312 12.6779 15.9746 14.3015 14.4163 15.5809C12.858 16.8604 11.0622 17.5002 9.02884 17.5002ZM9.02884 16.0002C10.4955 16.0002 11.8122 15.596 12.9788 14.7877C14.1455 13.9793 14.9955 12.9252 15.5288 11.6252C15.1955 11.7085 14.8622 11.7752 14.5288 11.8252C14.1955 11.8752 13.8622 11.9002 13.5288 11.9002C11.4788 11.9002 9.73301 11.1793 8.29134 9.73768C6.84968 8.29601 6.12884 6.55018 6.12884 4.50018C6.12884 4.16685 6.15384 3.83351 6.20384 3.50018C6.25384 3.16685 6.32051 2.83351 6.40384 2.50018C5.10384 3.03351 4.04968 3.88351 3.24134 5.05018C2.43301 6.21685 2.02884 7.53351 2.02884 9.00018C2.02884 10.9335 2.71218 12.5835 4.07884 13.9502C5.44551 15.3168 7.09551 16.0002 9.02884 16.0002Z"/></svg>')`
      }
    }
  },
  '& .MuiSwitch-thumb': {
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        color
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }
  }
})

const ProfileItem = () => {
  const { mode, palette } = useTheme()
  const color = palette?.surface?.onSurface
  const avatar = useSelector(path(['user', 'publicUser', 'avatar']))
  const { switchColorMode } = useContext(ThemeContext)

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={1}
      sx={{ px: 1.5 }}
    >
      <Button
        LinkComponent={Link}
        startIcon={<Avatar src={avatar} sx={{ height: 24, width: 24 }} />}
        sx={{
          color: 'unset',
          fontWeight: 400
        }}
        to="/profile?active=about"
        variant="sidebar"
      >
        Profile
      </Button>
      <FormControlLabel
        aria-label={`dark mode, ${equals(mode, 'dark') ? 'checked' : 'unchecked'}`}
        control={
          <Switch
            checked={equals(mode, 'dark')}
            disableRipple
            onChange={switchColorMode}
            sx={style(color)}
          />
        }
      />
    </Stack>
  )
}

export default ProfileItem
