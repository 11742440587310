import { Button, Grow, Stack } from '@mui/material'
import { useAnalytics } from 'hooks'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { iconType } from 'utils/helpers'

const LinkList = ({ priorities }) => {
  const { sendEvent } = useAnalytics()

  // Analytics
  const handleClick = title =>
    sendEvent('Priority_visit', {
      'Visited Priority': title
    })

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 1,
        justifyContent: 'center'
      }}
    >
      {priorities.map(({ link, section_id, title }) => (
        <Grow in key={title}>
          <Button
            LinkComponent={Link}
            onClick={() => handleClick(title)}
            startIcon={iconType(section_id)}
            to={link}
            variant="tonal"
          >
            {title}
          </Button>
        </Grow>
      ))}
    </Stack>
  )
}

LinkList.propTypes = {
  priorities: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default LinkList
