import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { gte, length, lte, or, path } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { setShowSeeAll } from './prioritiesSlice'

import CardAnimationWrapper from 'components/cards/CardAnimationWrapper'
import ImageCard from '../../../../components/cards/ImageCard'
import { useWindowResize } from 'hooks'

const CardsList = ({ priorities }) => {
  const dispatch = useDispatch()
  const isSeeAll = useSelector(path(['priorities', 'isSeeAll']))
  const containerRef = useRef(null)
  const { x } = useWindowResize()

  // All for showing/hiding the See All button in the header
  // and to determine if the container is a scroll or not
  // If the total number of priorities is greater or equal
  // to that of the container width then show the scroll bar
  // and show the see all button
  const containerWidth = containerRef.current?.offsetWidth
  const totalToFit = containerWidth / 200
  const totalMultiple = length(priorities)

  const [scrollType, setScrollType] = useState('unset')

  useEffect(() => {
    if (or(gte(totalMultiple, totalToFit), isSeeAll)) {
      dispatch(setShowSeeAll(true))
      setScrollType('auto')
      return
    }
    dispatch(setShowSeeAll(false))
    setScrollType('unset')
  }, [dispatch, isSeeAll, priorities, totalMultiple, totalToFit, x])

  return (
    <Stack
      direction="row"
      ref={containerRef}
      sx={{
        flexWrap: isSeeAll ? 'wrap' : 'unset',
        gap: 1.5,
        justifyContent: or(lte(totalMultiple, totalToFit), isSeeAll)
          ? 'center'
          : 'flex-start',
        overflowX: scrollType
      }}
    >
      {priorities.map(({ display_image, id, title, link }) => (
        <CardAnimationWrapper key={id}>
          <ImageCard imgURL={display_image} link={link} title={title} />
        </CardAnimationWrapper>
      ))}
    </Stack>
  )
}

CardsList.propTypes = {
  priorities: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default CardsList
