import { css, Global } from '@emotion/react'

const HeadComponent = () => (
  <Global
    styles={css`
      // Calendar styling
      .MuiPickersCalendarHeader-labelContainer {
        white-space: nowrap;
      }

      // Safari only
      @media not all and (min-resolution: 0.001dpcm) {
        -webkit-font-smoothing: subpixel-antialiased;
      }

      @keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .input--focused {
        animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
      }

      .fill {
        font-variation-settings: 'FILL' 1;
      }
    `}
  />
)

export default HeadComponent
