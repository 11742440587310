/* eslint-disable sort-keys */
import { Box, Button, Stack, Typography } from '@mui/material'
import { useUpdateProfileMutation } from 'api/profileApi'
import { and, not, path } from 'ramda'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useAnalytics } from 'hooks'
import { Link } from 'react-router-dom'
import Icon from 'components/icon/Icon'

const ECRM = () => {
  const { sendEvent } = useAnalytics()
  const isOptedIn = useSelector(path(['user', 'email_opt_in']))

  const [hasInteracted, setHasInteracted] = useState(false)

  const [updateProfile] = useUpdateProfileMutation()

  const handleClick = async () => {
    const body = new FormData()
    body.append('email_opt_in', 1)
    try {
      await updateProfile(body)
      sendEvent('email_opt_in', {
        email_opt_in: true,
        opt_in_element: 'Explore banner'
      })
      setHasInteracted(true)
    } catch (err) {
      console.warn('Error updating email opt in, ECRM, Explore Page:', err)
    }
  }

  return (
    <Stack
      sx={{
        alignItems: 'center',
        width: 1
      }}
    >
      <Stack
        direction="column"
        sx={{
          backgroundColor: 'surface.container',
          backgroundImage: {
            mobile: 'none',
            tablet: 'url(/images/ecrm-explore.png)'
          },
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          borderRadius: 4,
          boxShadow: ({ shadows }) => shadows[2],
          display: and(isOptedIn, not(hasInteracted)) ? 'none' : 'flex',
          maxWidth: '75rem',
          px: 4.5,
          py: 5,
          width: 1
        }}
      >
        <Box
          sx={{
            display: 'grid',
            maxWidth: { mobile: 'unset', tablet: 459 },
            width: { mobile: 1, tablet: '50%' }
          }}
        >
          <Typography variant="h2">
            <FormattedMessage
              defaultMessage="Focus on what matters."
              id="ajmgcG"
            />
          </Typography>
          {hasInteracted ? (
            <>
              <Typography variant="body2">
                <FormattedMessage
                  defaultMessage="You’re subscribed to our newsletter! "
                  id="Ll6F6G"
                />
              </Typography>
              <Button
                endIcon={<Icon>arrow_forward</Icon>}
                LinkComponent={Link}
                sx={{
                  fontWeight: 900,
                  mt: 2,
                  px: 0
                }}
                to="/profile?active=settings"
                variant="text"
              >
                <FormattedMessage
                  defaultMessage="Update settings Any time"
                  id="YHi7FW"
                />
              </Button>
            </>
          ) : (
            <>
              <Typography variant="body2">
                <FormattedMessage
                  defaultMessage="Get well-being tips and resources right to your inbox! No spam, we promise. Sign up for emails to keep your well-being top of mind."
                  id="+rztPn"
                />
              </Typography>
              <Button onClick={handleClick} sx={{ mt: 2 }} variant="tonal">
                <FormattedMessage defaultMessage="Subscribe" id="gczcC5" />
              </Button>
            </>
          )}
        </Box>
      </Stack>
    </Stack>
  )
}

export default ECRM
