import { MenuItem, Select, Stack, Typography } from '@mui/material'
import { assoc, compose, defaultTo, path, prop, propEq, tap } from 'ramda'
import { useUpdateUserMutation } from 'api/userApi'
import { useDispatch, useSelector } from 'react-redux'
import { setUserData } from 'store/userSlice'
import PropTypes from 'prop-types'

const LocationSelect = ({ title = null }) => {
  const dispatch = useDispatch()
  const { location_id } = useSelector(prop('user'))
  const { locations } = useSelector(prop('school'))
  const [updateUser] = useUpdateUserMutation()

  const options = locations.map(({ id, name }) => ({
    label: name,
    value: id
  }))
  const foundValue = options.find(propEq(location_id, 'value'))

  const handleUpdateUser = location_id => updateUser({ location_id })

  const handleSetUser = location => dispatch(setUserData(location))

  return (
    <Stack spacing={1} sx={{ maxWidth: 481, width: 1 }}>
      {title && (
        <Typography component="label" id="location" variant="h4">
          {title}
        </Typography>
      )}
      <Select
        aria-label="Campus Affiliation"
        labelId="location"
        onChange={compose(
          handleSetUser,
          assoc('location_id'),
          tap(handleUpdateUser),
          path(['target', 'value'])
        )}
        value={defaultTo('', prop('value', foundValue))}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}

LocationSelect.propTypes = {
  title: PropTypes.string
}

export default LocationSelect
