import { useGetFeaturedCardsQuery } from 'api/cardsApi'
import { any, assoc, equals, isNil, prop } from 'ramda'
import { useSelector } from 'react-redux'

import CardHandler from 'components/cards/CardHandler'
import CardGridWrapper from 'components/cards/CardGridWrapper'
import { useMemo } from 'react'

const FeaturedCards = () => {
  const { domain } = useSelector(prop('school'))
  const {
    is_secondary_user: isSecondaryUser,
    location_id: location,
    isSuccess
  } = useSelector(prop('user'))

  const params = useMemo(() => {
    const base = {
      is_staff_faculty: isSecondaryUser ? 1 : 0,
      org_domain: domain
    }

    return location ? assoc('location_id', location, base) : base
  }, [domain, isSecondaryUser, location])

  const { data = [], isFetching = true } = useGetFeaturedCardsQuery(params, {
    skip: any(equals(true))([
      equals(isSuccess, false),
      isNil(domain),
      isNil(isSecondaryUser)
    ])
  })

  return (
    <CardGridWrapper isLoading={isFetching} small>
      {data.map(({ card }) => (
        <CardHandler card={card} isFeatured key={prop('id', card)} small />
      ))}
    </CardGridWrapper>
  )
}

export default FeaturedCards
