import PropTypes from 'prop-types'
import {
  Box,
  CardActionArea,
  CardContent,
  Stack,
  Typography
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { useAnalytics } from 'hooks'
import { hexToRGBA } from 'utils/helpers'
import Icon from 'components/icon/Icon'
import { equals } from 'ramda'

const ImageCard = ({ imgURL, link, title }) => {
  const { pathname } = useLocation()
  const { sendEvent } = useAnalytics()

  // Analytics
  // On Explore Page do the block interaction
  const handleClick = () => {
    if (equals('/explore', pathname)) {
      return sendEvent('block_interactions', {
        block_title: 'Featured Cards Block',
        click_element: 'Link',
        element_title: title,
        path: pathname
      })
    }
    sendEvent('Priority_visit', {
      'Visited Priority': title
    })
  }

  return (
    <Stack
      sx={{
        width: 200
      }}
    >
      <CardActionArea
        component={Link}
        onClick={handleClick}
        sx={{
          ':hover > .MuiCardActionArea-focusHighlight': {
            opacity: 0
          },
          ':hover > div > span': {
            color: 'button.text.onSurface'
          },
          ':hover > div:first-of-type': {
            backgroundColor: 'surface.low'
          }
        }}
        to={link}
      >
        <Box
          sx={{
            backgroundColor: ({ palette }) =>
              `${hexToRGBA(palette.surface.container, 92)}`,
            backgroundImage: `url(${imgURL})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: 4,
            height: 180,
            width: 1
          }}
        />
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography
            aria-hidden
            component="span"
            sx={{
              display: 'block'
            }}
            variant="subtitle1"
          >
            {title}
          </Typography>
          <Icon
            sx={{
              fontSize: '14px !important',
              height: 14,
              width: 14
            }}
          >
            arrow_forward
          </Icon>
        </CardContent>
      </CardActionArea>
    </Stack>
  )
}

ImageCard.propTypes = {
  imgURL: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default ImageCard
