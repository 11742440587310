export const MuiAccordion = palette => ({
  styleOverrides: {
    content: {
      margin: '16px 0'
    },
    root: {
      ':before': {
        content: 'none'
      },
      ':first-of-type': {
        borderRadius: 16
      },
      backgroundColor: palette.surface.high,
      borderRadius: 16,
      boxShadow: 'none',
      marginBottom: 16
    }
  }
})
