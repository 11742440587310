import PropTypes from 'prop-types'
import { Button, Stack } from '@mui/material'
import { equals, isNil, map, not, prop } from 'ramda'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { staffMappedSubdomain, studentMappedSubdomain } from './mappedSubdomain'
import { useAnalytics } from 'hooks'

import Icon from 'components/icon/Icon'

const SubdomainSelection = ({ refetch, selected, setSelected }) => {
  const { sendEvent } = useAnalytics()
  const { is_secondary_user, userDomains } = useSelector(prop('user'))

  const subdomains = useMemo(() => {
    if (is_secondary_user) return staffMappedSubdomain
    return studentMappedSubdomain
  }, [is_secondary_user])

  const relatedSubdomains = (arr = []) => {
    const subdomains = userDomains.reduce(
      (subdomains, domain) => domain.subdomains.concat(subdomains),
      []
    )
    return map(
      prop('title'),
      subdomains.filter(({ id }) => arr.some(equals(id)))
    )
  }

  const handleSelect = (name, subdomains) => {
    sendEvent('Check_in', {
      'Element Title': 'Feeling',
      Feeling: name,
      'Related Subdomains': relatedSubdomains(subdomains)
    })
    if (equals(name, selected)) return setSelected(null)
    return setSelected(name)
  }

  return (
    <Stack
      direction="row"
      sx={{ flexWrap: 'wrap', gap: 1.5, justifyContent: 'center' }}
    >
      {subdomains.map(({ name, subdomains }) => (
        <Button
          key={name}
          onClick={() => handleSelect(name, subdomains)}
          selected={equals(name, selected)}
          variant="select"
        >
          {name}
        </Button>
      ))}
      {not(isNil(selected)) && (
        <Button endIcon={<Icon fill>shuffle</Icon>} onClick={refetch}>
          Shuffle Results
        </Button>
      )}
    </Stack>
  )
}

SubdomainSelection.propTypes = {
  refetch: PropTypes.func.isRequired,
  selected: PropTypes.string,
  setSelected: PropTypes.func.isRequired
}

export default SubdomainSelection
