import PropTypes from 'prop-types'
import { Stack } from '@mui/material'
import { find, isNil, not, path, prop, propEq, sortBy } from 'ramda'
import { useSelector } from 'react-redux'

import SelfCheckItem from './SelfCheckItem'

const SelfChecks = ({ selfChecks = [] }) => {
  const { userDomains } = useSelector(prop('user'))

  const findDomain = domain =>
    find(propEq(prop('id', domain), 'id'), selfChecks)

  return (
    <Stack alignItems="center" spacing={3}>
      <Stack
        // eslint-disable-next-line sort-keys
        direction={{ mobile: 'column', laptop: 'row' }}
        spacing={2}
        sx={{ flexWrap: 'wrap', justifyContent: 'center', width: 1 }}
      >
        {sortBy(prop('sort'), userDomains).map(domain => (
          <SelfCheckItem
            domainUrlSlug={prop('frontend_url_slug', domain)}
            isCompleted={not(
              isNil(prop('last_completed_at', findDomain(domain)))
            )}
            key={prop('id', domain)}
            lastCompleted={path(
              ['last_completed_at', 'date'],
              findDomain(domain)
            )}
            subtitle={prop('subtitle', domain)}
            title={prop('frontend_name', domain)}
          />
        ))}
      </Stack>
    </Stack>
  )
}

SelfChecks.propTypes = {
  selfChecks: PropTypes.arrayOf(PropTypes.shape({}))
}

export default SelfChecks
