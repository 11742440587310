import { FormattedMessage } from 'react-intl'
import { Button, CircularProgress, Stack } from '@mui/material'
import { useSignInMutation } from 'api/authApi'
import { path, toLower, defaultTo, prop, or, isNil } from 'ramda'
import { useAnalytics, useLocalStorage, useSession } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetState } from 'store/authenticationSlice'
import { useState } from 'react'

import EmailAndPassword from 'components/authentication/EmailAndPassword'
import Toast from 'components/toast/Toast'

const BasicSignIn = () => {
  const dispatch = useDispatch()
  const { domain } = useSelector(prop('school'))
  const { email, password } = useSelector(prop('authentication'))
  const navigate = useNavigate()
  const { sendEvent } = useAnalytics()
  const { sessionEvent } = useSession()
  const { save } = useLocalStorage()

  const [isDisabled, setIsDisabled] = useState(false)

  const [signIn, { isError, error = { data: { email: '' } } }] =
    useSignInMutation()

  const onSubmit = async () => {
    const body = {
      auth_type: 'traditional',
      email: toLower(email),
      org_domain: domain,
      password
    }
    try {
      setIsDisabled(true)
      const data = await signIn(body).unwrap()
      const sessionId = path(['headers', 'headers'], data).get('X-Session-Id')
      save(sessionId)
      await sessionEvent('login', { session_id: sessionId })
      await sendEvent('login', {
        auth_type: 'traditional',
        validation_errors: [],
        validation_success: true
      })
      await sendEvent('sign-in', {
        auth_type: 'traditional',
        validation_errors: [],
        validation_success: true
      })
      dispatch(resetState())
      navigate('/home')
    } catch (err) {
      console.warn('Error Signing In:', err)
      setIsDisabled(false)
      await sendEvent('login', {
        auth_type: 'traditional',
        validation_errors: [],
        validation_success: false
      })
      await sendEvent('sign-in', {
        auth_type: 'traditional',
        validation_errors: [],
        validation_success: false
      })
    }
  }

  return (
    <Stack
      component="form"
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
      spacing={2}
      sx={{ mt: 3, width: 1 }}
    >
      <Toast
        isShown={isError}
        message={defaultTo(
          path(['data', 'email'], error),
          path(['data', 'errors', 'email'], error)
        )}
        type="error"
      />
      <EmailAndPassword />
      <Button disabled={or(isDisabled, isNil(domain))} fullWidth type="submit">
        {isDisabled ? (
          <CircularProgress
            size={24}
            sx={{
              color: 'button.primary.onSurface'
            }}
          />
        ) : (
          <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
        )}
      </Button>
    </Stack>
  )
}

export default BasicSignIn
