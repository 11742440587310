import PropTypes from 'prop-types'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { and, not, path, prop } from 'ramda'
import { setIsPrioritesModalOpen, setIsSeeAll } from './prioritiesSlice'
import Icon from 'components/icon/Icon'

const Heading = ({ children }) => {
  const dispatch = useDispatch()
  const isSeeAll = useSelector(path(['priorities', 'isSeeAll']))
  const { display_priorities_as_images } = useSelector(prop('user'))
  const { showSeeAll } = useSelector(prop('priorities'))

  return (
    // eslint-disable-next-line sort-keys
    <Stack spacing={3} sx={{ px: { mobile: 1.5, laptop: 'unset' }, width: 1 }}>
      <Box
        sx={{
          display: 'grid',
          gap: { mobile: 2, tablet: 'unset' },
          gridAutoFlow: 'row',
          gridTemplateAreas: {
            mobile: '"Heading Heading" "Text Text"',
            tablet: '"Heading Text Edit"'
          },
          gridTemplateColumns: {
            mobile: 'repeat(2, 1fr)',
            tablet: 'repeat(3, 1fr)'
          },
          width: 1
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{
            gridArea: 'Heading',
            justifySelf: { mobile: 'center', tablet: 'start' }
          }}
        >
          <Typography component="h2" variant="h4">
            Priorities
          </Typography>
          {and(display_priorities_as_images, showSeeAll) && (
            <Button
              onClick={() => dispatch(setIsSeeAll(not(isSeeAll)))}
              sx={{
                display: { mobile: 'none', tablet: 'inline-flex' }
              }}
              variant="outlined"
            >
              See {isSeeAll ? 'Less' : 'All'}
            </Button>
          )}
          <IconButton
            aria-label="Edit Priorities"
            onClick={() => dispatch(setIsPrioritesModalOpen(true))}
            sx={{
              display: { mobile: 'inline-flex', tablet: 'none' }
            }}
          >
            <Icon fill>edit</Icon>
          </IconButton>
        </Stack>
        <Typography
          component="span"
          sx={{
            gridArea: 'Text',
            textAlign: 'center'
          }}
          variant="subtitle1"
        >
          Your saved priorities. Click one to dive in.
        </Typography>
        <IconButton
          aria-label="Edit Priorities"
          onClick={() => dispatch(setIsPrioritesModalOpen(true))}
          sx={{
            display: { mobile: 'none', tablet: 'inline-flex' },
            gridArea: 'Edit',
            justifySelf: { mobile: 'start', tablet: 'end' },
            width: 40
          }}
        >
          <Icon>edit</Icon>
        </IconButton>
      </Box>
      {children}
      {and(display_priorities_as_images, showSeeAll) && (
        <Button
          onClick={() => dispatch(setIsSeeAll(not(isSeeAll)))}
          sx={{
            alignSelf: 'center',
            display: { mobile: 'inline-flex', tablet: 'none' }
          }}
          variant="outlined"
        >
          See {isSeeAll ? 'Less' : 'All'}
        </Button>
      )}
    </Stack>
  )
}

Heading.propTypes = {
  children: PropTypes.node.isRequired
}

export default Heading
