import { IconButton, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { append, isEmpty, isNil, map, prop, propEq, without } from 'ramda'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAnalytics } from 'hooks'
import { useUpdateInterestsMutation } from 'api/userApi'
import Icon from 'components/icon/Icon'

const PrioritizedIcon = ({ subdomainId, title }) => {
  const { sendEvent } = useAnalytics()
  const { interestedSubdomains } = useSelector(prop('user'))

  const [updateInterests] = useUpdateInterestsMutation()

  const isUserPriority = useMemo(() => {
    if (isEmpty(interestedSubdomains)) return false
    return interestedSubdomains.some(({ onboarding_title }) =>
      onboarding_title.includes(title)
    )
  }, [interestedSubdomains, title])

  const handleClick = async () => {
    try {
      const found = interestedSubdomains.find(propEq(subdomainId, 'id'))
      sendEvent('Priorities_update', {
        'Element Title': 'Priority Update',
        'Page Title': 'Subdomain',
        'Priority Name': title,
        Status: isNil(found) ? 'Selected' : 'Unselected'
      })
      if (isNil(found)) {
        await updateInterests({
          subdomains: append(subdomainId, map(prop('id'), interestedSubdomains))
        })
        return
      }
      await updateInterests({
        subdomains: without(
          [subdomainId],
          map(prop('id'), interestedSubdomains)
        )
      })
    } catch (err) {
      console.warn('Unable to update priority', err)
    }
  }

  return (
    <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
      <IconButton
        aria-label={
          isUserPriority ? `Selected: ${title}` : `Unselected: ${title}`
        }
        onClick={handleClick}
      >
        <Icon
          fill={isUserPriority}
          sx={{
            color: 'button.primary.background'
          }}
        >
          bookmark
        </Icon>
      </IconButton>
      <Typography variant="caption">
        {isUserPriority ? 'Your Priority' : 'Set as a Priority'}
      </Typography>
    </Stack>
  )
}

PrioritizedIcon.propTypes = {
  subdomainId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
}

export default PrioritizedIcon
