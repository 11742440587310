/* eslint-disable sort-keys */
import {
  and,
  append,
  equals,
  filter,
  isNil,
  length,
  map,
  not,
  path,
  prop,
  propEq,
  reduce,
  reverse,
  sortBy,
  without
} from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { setIsPrioritesModalOpen } from './prioritiesSlice'
import AccessibleModal from 'components/modals/AccessibleModal'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Typography
} from '@mui/material'
import { iconType } from 'utils/helpers'
import { useUpdateInterestsMutation } from 'api/userApi'
import { updateUserData } from 'store/userSlice'
import { useCallback, useEffect, useState } from 'react'
import { useAnalytics } from 'hooks'
import Icon from 'components/icon/Icon'

const PrioritiesModal = () => {
  const { sendEvent } = useAnalytics()
  const dispatch = useDispatch()
  const isPrioritiesModalOpen = useSelector(
    path(['priorities', 'isPrioritiesModalOpen'])
  )
  const {
    display_priorities_as_images,
    interestedSubdomains = [],
    userDomains = []
  } = useSelector(prop('user'))

  const [tempSelected, setTempSelected] = useState(false)
  const [tempSelectedSubdomains, setTempSelectedSubdomains] = useState([])

  useEffect(() => {
    setTempSelectedSubdomains(interestedSubdomains)
    setTempSelected(display_priorities_as_images)
  }, [interestedSubdomains, display_priorities_as_images])

  const [updateInterests, { isLoading }] = useUpdateInterestsMutation()

  const handleClose = () => dispatch(setIsPrioritesModalOpen(false))

  const subdomains = reduce(
    (subdomains, domain) => subdomains.concat(domain.subdomains),
    [],
    userDomains
  )

  const getSubdomains = useCallback(
    id => {
      if (isNil(subdomains)) return []
      return reverse(
        sortBy(prop('sort'))(filter(propEq(id, 'section_id'), subdomains))
      )
    },
    [subdomains]
  )

  const updatePriorities = async () => {
    try {
      dispatch(updateUserData({ name: 'has_viewed_a_priority', value: true }))
      dispatch(
        updateUserData({
          name: display_priorities_as_images,
          value: not(display_priorities_as_images)
        })
      )
      dispatch(
        updateUserData({
          name: 'interestedSubdomains',
          value: tempSelectedSubdomains
        })
      )
      await updateInterests({
        display_priorities_as_images: tempSelected,
        subdomains: map(prop('id'), tempSelectedSubdomains)
      })
      sendEvent('Priorities_update', {
        'Element Title': 'Save Priorities',
        'Page Title': 'Home'
      })
      handleClose()
    } catch (err) {
      console.error('Error updating priorities', err)
    }
  }

  const handleClick = priority => {
    const found = tempSelectedSubdomains.find(
      propEq(prop('id', priority), 'id')
    )
    sendEvent('Priorities_update', {
      'Element Title': 'Priority Update',
      'Page Title': 'Home',
      'Priority Name': prop('title', priority),
      Status: isNil(found) ? 'Selected' : 'Unselected'
    })
    if (isNil(found)) {
      setTempSelectedSubdomains(append(priority, tempSelectedSubdomains))
      return
    }
    setTempSelectedSubdomains(without([priority], tempSelectedSubdomains))
  }

  const total = useCallback(
    id =>
      getSubdomains(id).filter(subdomain =>
        tempSelectedSubdomains.some(propEq(prop('id', subdomain), 'id'))
      ),
    [getSubdomains, tempSelectedSubdomains]
  )

  return (
    <AccessibleModal
      onClose={handleClose}
      open={isPrioritiesModalOpen}
      title={
        <Stack alignItems="center" spacing={2}>
          <Icon fill>edit</Icon>
          <Typography textAlign="center" variant="overline">
            Set your priorities
          </Typography>
        </Stack>
      }
    >
      {userDomains.map(({ id, frontend_name, subtitle }) => (
        <Accordion
          key={id}
          sx={{ maxWidth: 855, minWidth: { mobile: 1, laptop: 855 }, width: 1 }}
        >
          <AccordionSummary
            aria-controls="panel-content"
            expandIcon={<Icon>keyboard_arrow_down</Icon>}
            id={`panel-header-${id}`}
            sx={{ gap: 1.5 }}
          >
            <Stack alignItems="center" direction="row" spacing={1.5}>
              {iconType(id)}
              <Typography
                sx={{
                  display: { mobile: 'none', laptop: 'block' },
                  textTransform: 'capitalize'
                }}
                variant="h4"
              >
                {frontend_name} |
              </Typography>
              <Typography sx={{ textTransform: 'capitalize' }} variant="h4">
                {subtitle} ({length(total(id))})
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1.5
            }}
          >
            {getSubdomains(id).map(subdomain => (
              <Button
                aria-disabled={isLoading ? 'true' : 'false'}
                aria-label={
                  tempSelectedSubdomains.some(
                    propEq(prop('id', subdomain), 'id')
                  )
                    ? `Selected: ${frontend_name} ${prop('title', subdomain)}`
                    : `Unselected: ${frontend_name} ${prop('title', subdomain)}`
                }
                disabled={isLoading}
                key={prop('id', subdomain)}
                onClick={() => not(isLoading) && handleClick(subdomain)}
                selected={tempSelectedSubdomains.some(
                  propEq(prop('id', subdomain), 'id')
                )}
                sx={{
                  maxHeight: 'unset',
                  whiteSpace: 'wrap'
                }}
                variant="select"
              >
                {prop('title', subdomain)}
              </Button>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      <Stack
        alignItems="center"
        direction={{ mobile: 'column', tablet: 'row' }}
        justifyContent="space-between"
        sx={{ gap: 4, mt: 4 }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <Typography variant="body1">View priorities as</Typography>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Typography>Images</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={not(tempSelected)}
                  disableRipple
                  onChange={() => setTempSelected(not(tempSelected))}
                  sx={{
                    '& .MuiSwitch-switchBase': {
                      '&.Mui-checked': {
                        '& + .MuiSwitch-track': {
                          border: '2px solid',
                          borderColor: 'surface.onSurface'
                        }
                      }
                    },
                    '& .MuiSwitch-thumb': {
                      backgroundColor: 'surface.onSurface'
                    },
                    '& .MuiSwitch-track': {
                      border: '2px solid',
                      borderColor: 'surface.onSurface'
                    }
                  }}
                />
              }
            />
            <Typography>Text</Typography>
          </Stack>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={3}>
          <Button onClick={handleClose} variant="text">
            Cancel
          </Button>
          <Button
            // Disable until user interacts and changes
            disabled={and(
              equals(interestedSubdomains, tempSelectedSubdomains),
              equals(tempSelected, display_priorities_as_images)
            )}
            onClick={updatePriorities}
          >
            Save Changes
          </Button>
        </Stack>
      </Stack>
    </AccessibleModal>
  )
}

export default PrioritiesModal
