export const MuiTooltip = palette => ({
  styleOverrides: {
    arrow: {
      ':before': {
        backgroundColor: palette.surface.container
      }
    },
    tooltip: {
      '& > .MuiTypography-root': {
        color: palette.surface.onSurface
      },
      '@media(max-width: 768px)': {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 'calc(100% - 16px)'
      },
      backgroundColor: palette.surface.container,
      borderRadius: 16,
      maxWidth: 'unset',
      width: 'auto'
    }
  }
})
