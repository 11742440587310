import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { not } from 'ramda'

const ContentPanel = ({ children, isActive, value }) => (
  <Box
    aria-labelledby={`stats-tab-${value}`}
    component="div"
    hidden={not(isActive)}
    id={`stats-panel-${value}`}
    role="tabpanel"
    sx={{ m: 0, p: 0 }}
    tabIndex={-1}
  >
    {isActive && children}
  </Box>
)

ContentPanel.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default ContentPanel
