export const MuiPaper = palette => ({
  styleOverrides: {
    root: {
      '& > .MuiDialog-paper': {
        '@media (max-width:600px)': {
          margin: 20
        }
      },
      backgroundColor: palette.surface.low,
      borderRadius: 16,
      color: palette.surface.onSurface
    }
  }
})
