import { Box, Button, Stack, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setIsPrioritesModalOpen } from './prioritiesSlice'
import Icon from 'components/icon/Icon'

const EmptyState = () => {
  const dispatch = useDispatch()

  return (
    <Stack
      alignItems="center"
      spacing={3}
      sx={{
        py: 3
      }}
    >
      <Stack
        alignItems="center"
        direction={{ mobile: 'column', tablet: 'row' }}
        spacing={3}
      >
        <Typography component="h2" variant="body1">
          Let us know what you think is important
        </Typography>
        <Button
          onClick={() => dispatch(setIsPrioritesModalOpen(true))}
          startIcon={<Icon fill>edit</Icon>}
        >
          Set Your Priorities
        </Button>
      </Stack>
      <Box
        sx={{
          backgroundImage: 'url(/vectors/stars.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: 109,
          width: 147
        }}
      />
    </Stack>
  )
}

export default EmptyState
