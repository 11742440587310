import { useSelector } from 'react-redux'
const { not, prop, and, or } = require('ramda')

// Eventually will have logic for other types
// of spotlight features in future
const useGetHelperList = () => {
  const { enable_goals, enable_tips } = useSelector(prop('school'))
  const { appType, is_secondary_user } = useSelector(prop('user'))
  const { type } = useSelector(prop('spotlight'))

  //GOALS
  const showGoalsForPrimaryUser = and(not(is_secondary_user), enable_goals)
  const showGoalsForSecondaryUser = and(
    is_secondary_user,
    prop('enable_goals', appType)
  )
  const showGoals = or(showGoalsForPrimaryUser, showGoalsForSecondaryUser)

  // Best to create an object of list type
  // then to map what steps may have additional
  // lists to help the spotlight
  const lists = {
    introduction: {
      0: [
        {
          icon: 'search',
          subtitle: 'Use keywords and filters to find what you’re looking for',
          title: 'Search'
        },
        {
          icon: 'explore',
          subtitle: 'Browse specific topics, or see what’s New & Trending',
          title: 'Explore'
        },
        {
          icon: 'contract_edit',
          isShown: and(enable_tips, not(is_secondary_user)),
          subtitle: 'Get inspired by other students',
          title: 'Student posts'
        },
        {
          icon: 'note_stack',
          isShown: is_secondary_user,
          subtitle: 'Support students by exploring content made for them',
          title: 'For Students'
        }
      ],
      1: [
        {
          icon: 'spa',
          subtitle: 'Take some quizzes, and we’ll recommend topics to focus on',
          title: 'Self checks'
        },
        {
          icon: 'mountain_flag',
          isShown: showGoals,
          subtitle:
            'Set your own personal goals or use our pre-made ideas, then chart your progress toward reaching them',
          title: 'Goals'
        },
        {
          icon: 'bookmark',
          subtitle: 'Bookmark and sort your favorite resources',
          title: 'favorites'
        }
      ],
      6: [
        {
          icon: 'edit',
          subtitle:
            'Fill out your profile to get personalized content and recommendations (everything you share is completely confidential)',
          title: 'profile'
        },
        {
          icon: 'dark_mode',
          subtitle: 'Give your eyes a break with dark mode',
          title: 'Dark mode'
        }
      ]
    }
  }

  return prop(type, lists)
}

export default useGetHelperList
