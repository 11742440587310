import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import { prop, assoc, not } from 'ramda'
import { Stack } from '@mui/material'
import { useSendFeedbackMutation } from 'api/userApi'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FlowHeader from 'components/header/FlowHeader'
import Toast from 'components/toast/Toast'
import Form from './components/Form'
import Success from './components/Success'

const initialFormData = {
  email: '',
  emailError: false,
  error: false,
  success: false,
  text: ''
}

const FeedbackPage = () => {
  const { history } = useSelector(prop('history'))
  const { name } = useSelector(prop('school'))

  const [formData, setFormData] = useState(initialFormData)
  const [hasSuccess, setHasSuccess] = useState(false)

  const email = prop('email', formData)

  const { pathname } = useLocation()

  const [send, { isFetching = false }] = useSendFeedbackMutation()

  const sendFeedback = async () => {
    setFormData(assoc('emailError', false, formData))
    const body = new FormData()
    body.append('page', pathname)
    body.append('body', prop('text', formData))
    body.append('email', email)
    body.append('prior_path', prop(1, history))
    body.append('school_name', name)
    try {
      await send(body)
      setHasSuccess(true)
      setFormData(assoc('success', true, formData))
      setFormData(initialFormData)
    } catch (err) {
      console.warn('Error giving feedback:', err)
      setFormData(assoc('error', true, formData))
      setFormData(initialFormData)
    }
  }

  return (
    <Stack
      sx={{
        pb: 8
      }}
    >
      <FlowHeader />
      <Stack
        spacing={3}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 700,
          mt: hasSuccess ? 13 : 3,
          mx: { mobile: 3, tablet: 'auto' }
        }}
      >
        {not(hasSuccess) && (
          <Form
            formData={formData}
            isFetching={isFetching}
            sendFeedback={sendFeedback}
            setFormData={setFormData}
          />
        )}

        {hasSuccess && <Success />}
        <Toast
          isShown={prop('error', formData)}
          message={
            <FormattedMessage
              defaultMessage="There was an error processing your feedback, please retry."
              id="C8QPke"
            />
          }
          type="error"
        />
      </Stack>
    </Stack>
  )
}

export default FeedbackPage
