import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

// How to use this icon:
// https://fonts.google.com/icons?icon.style=Outlined&icon.size=24&icon.color=%23e8eaed
const Icon = ({ children, fill = false, sx = {} }) => (
  <Typography
    aria-hidden="true"
    className={`notranslate material-symbols-outlined ${fill ? 'fill' : ''}`}
    component="span"
    sx={{
      ...sx
    }}
  >
    {children}
  </Typography>
)

Icon.propTypes = {
  children: PropTypes.string.isRequired,
  fill: PropTypes.bool,
  sx: PropTypes.shape({})
}

export default Icon
