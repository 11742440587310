/* eslint-disable sort-keys */
import { Button, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import {
  and,
  isEmpty,
  isNil,
  not,
  path,
  prop,
  reverse,
  slice,
  sortBy
} from 'ramda'
import { Link } from 'react-router-dom'
import { useAnalytics } from 'hooks'

import CardHandler from 'components/cards/CardHandler'
import Icon from 'components/icon/Icon'

const Resources = ({ resources }) => {
  const { sendEvent } = useAnalytics()

  const handleLinkClick = () =>
    sendEvent('Stats_bar_favorites', {
      'Element Title': 'Your Resources'
    })

  return and(not(isNil(resources)), not(isEmpty(resources))) ? (
    <Stack spacing={1.5}>
      <Button
        endIcon={<Icon>arrow_forward</Icon>}
        LinkComponent={Link}
        onClick={handleLinkClick}
        sx={{
          alignSelf: 'flex-end',
          display: { mobile: 'none', laptop: 'inline-flex' }
        }}
        to="/favorites"
        variant="text"
      >
        Your Resources
      </Button>
      <Stack
        direction="row"
        sx={{
          '& > div > div[class*=MuiCard-root]': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between'
          },
          alignItems: 'stretch',
          flexWrap: 'wrap',
          gap: 3,
          justifyContent: 'center'
        }}
      >
        {slice(
          0,
          3,
          reverse(sortBy(path(['pivot', 'genesis']), resources))
        ).map(resource => (
          <CardHandler card={resource} key={prop('id', resource)} small />
        ))}
      </Stack>
      <Button
        endIcon={<Icon>arrow_forward</Icon>}
        LinkComponent={Link}
        onClick={handleLinkClick}
        sx={{
          alignSelf: 'center',
          display: { mobile: 'inline-flex', laptop: 'none' }
        }}
        to="/favorites"
        variant="text"
      >
        Your Resources
      </Button>
    </Stack>
  ) : (
    <Stack alignItems="center" spacing={3}>
      <Typography variant="overline">Recently Saved</Typography>
      <Typography variant="body1">
        You don’t have any resources saved, yet.
      </Typography>
      <Button
        endIcon={<Icon>arrow_forward</Icon>}
        LinkComponent={Link}
        onClick={() =>
          sendEvent('Stats_bar_favorites', {
            'Element Title': 'Explore YOU'
          })
        }
        to="/explore"
      >
        Explore YOU
      </Button>
    </Stack>
  )
}

Resources.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.shape({}))
}

export default Resources
