import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { equals, length, prop } from 'ramda'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { iconType } from 'utils/helpers'

const ConnectedSubdomains = ({ subdomainData = {} }) => {
  const relatedDomains = Object.keys(subdomainData)
  const { userDomains } = useSelector(prop('user'))

  const relatedSubdomains = useMemo(() => {
    const subdomains = userDomains.reduce(
      (subdomains, domain) => domain.subdomains.concat(subdomains),
      []
    )
    return subdomains.filter(({ frontend_url_slug }) =>
      relatedDomains.some(equals(frontend_url_slug))
    )
  }, [relatedDomains, userDomains])

  if (equals(0, length(relatedDomains))) return null

  return (
    <Stack
      sx={{
        mt: 4,
        py: 4,
        width: 1
      }}
    >
      <Container maxWidth="tablet">
        <Stack alignItems="center" spacing={3}>
          <Stack>
            <Typography textAlign="center" variant="h4">
              <FormattedMessage
                defaultMessage="It's all connected..."
                id="mxJvGX"
              />
            </Typography>
            <Typography textAlign="center" variant="body1">
              <FormattedMessage
                defaultMessage="Explore other areas and ways to find balance."
                id="zPxuUS"
              />
            </Typography>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gridGap: 16,
              justifyContent: 'center',
              maxWidth: 600
            }}
          >
            {relatedSubdomains.map(({ link, section_id, title }) => (
              <Button
                key={title}
                LinkComponent={Link}
                startIcon={iconType(section_id)}
                to={link}
                variant="tonal"
              >
                {title}
              </Button>
            ))}
          </Box>
        </Stack>
      </Container>
    </Stack>
  )
}

ConnectedSubdomains.propTypes = {
  subdomainData: PropTypes.shape({})
}

export default ConnectedSubdomains
