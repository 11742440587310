import {
  Alert,
  AlertTitle,
  Button,
  Slide,
  Stack,
  Typography
} from '@mui/material'
import { useUpdateProfileMutation } from 'api/profileApi'
import { isToday, parseISO } from 'date-fns'
import { all, equals, isNil, not, path, prop } from 'ramda'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from 'store/userSlice'
import { setShowStaff } from './alertSlice'
import { Link, useSearchParams } from 'react-router-dom'

const StaffFacultyAlert = () => {
  const dispatch = useDispatch()
  const genesis = useSelector(path(['user', 'genesis']))
  const enableSecondaryFeatures = useSelector(
    path(['school', 'enable_secondary_user_type_features'])
  )
  const hasSeenStaffFacultyAnnouncement = useSelector(
    path(['user', 'has_seen_staff_faculty_announcement'])
  )
  const showStaff = useSelector(path(['alert', 'showStaff']))
  const { isSpotlightOpen } = useSelector(prop('spotlight'))
  const [search] = useSearchParams()
  const justOnboarded = search.get('justOnboarded')

  const [update] = useUpdateProfileMutation()

  // STAFF FACULTY
  // If account not created today
  // is school app type
  // enableSecondaryFeatures on school object
  // has not seen announcement before
  // spotlight tour and confirmation modal aren't open
  useEffect(() => {
    if (isNil(genesis)) return
    if (
      all(equals(true))([
        not(isToday(parseISO(genesis))),
        enableSecondaryFeatures,
        not(hasSeenStaffFacultyAnnouncement),
        not(isSpotlightOpen),
        not(justOnboarded)
      ])
    ) {
      dispatch(setShowStaff(true))
    }
  }, [
    genesis,
    enableSecondaryFeatures,
    hasSeenStaffFacultyAnnouncement,
    dispatch,
    isSpotlightOpen,
    justOnboarded
  ])

  const handleClick = () => {
    update({ has_seen_staff_faculty_announcement: true })
    dispatch(
      updateUserData({
        name: 'has_seen_staff_faculty_announcement',
        value: true
      })
    )
    dispatch(setShowStaff(false))
  }

  return (
    <Slide direction="left" in={showStaff}>
      <Alert
        component="div"
        icon={false}
        role="alert"
        severity="info"
        sx={{
          boxShadow: 6
        }}
        variant="outlined"
      >
        <AlertTitle>
          <FormattedMessage
            defaultMessage="Student or Staff/Faculty?"
            id="sT9B/p"
          />
        </AlertTitle>
        <Typography sx={{ display: 'inline' }} variant="body2">
          <FormattedMessage
            defaultMessage="YOU is customized to both students and staff/faculty members.
                Visit your profile settings to ensure you are experiencing the
                correct version of YOU."
            id="eQzYBj"
          />
        </Typography>
        <Stack direction="row" mt={2} spacing={2}>
          <Button
            LinkComponent={Link}
            onClick={handleClick}
            to="/profile?active=settings"
          >
            <FormattedMessage defaultMessage="Visit Profile" id="AZj8qj" />
          </Button>
          <Button onClick={handleClick} variant="outlined">
            <FormattedMessage defaultMessage="No Thanks" id="mgZWr0" />
          </Button>
        </Stack>
      </Alert>
    </Slide>
  )
}

export default StaffFacultyAlert
