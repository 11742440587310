/* eslint-disable sort-keys */
import { Box } from '@mui/material'
import CardLoader from 'components/cards/CardLoader'
import PropTypes from 'prop-types'

const CardGridWrapper = ({ children, isLoading = false, small = false }) => (
  <Box
    sx={{
      // This stretches all the cards to the same height
      // regardless of internal content
      // along with this container align items: stretch
      '& > div > div[class*=MuiCard-root]': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between'
      },
      alignItems: 'stretch',
      display: 'grid',
      gap: 3,
      gridAutoRows: 'min-content',
      gridTemplateColumns: {
        mobile: 'minmax(0, 320px)',
        tablet: 'repeat(2, minmax(0, 320px))',
        monitor: 'repeat(3, minmax(0, 320px))'
      },
      justifyContent: 'center',
      maxWidth: { mobile: 1008, monitor: '70rem' },
      width: 1
    }}
  >
    {isLoading ? <CardLoader small={small} /> : children}
  </Box>
)

CardGridWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  small: PropTypes.bool
}

export default CardGridWrapper
