/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { isEmpty, isNil, or, prop, slice } from 'ramda'
import { Stack } from '@mui/material'

import EntryCard from 'pages/destress/components/EntryCard'
import CardHandler from 'components/cards/CardHandler'

const CardGrid = ({ cards, selected }) =>
  or(isNil(selected), isEmpty(cards)) ? (
    <EntryCard />
  ) : (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center',
        width: 1
      }}
    >
      <CardHandler card={prop(0, cards)} />
      <Stack
        direction="row"
        sx={{
          // This stretches all the cards to the same height
          // regardless of internal content
          // along with this container align items: stretch
          '& > div > div[class*=MuiCard-root]': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between'
          },
          flexWrap: 'wrap',
          gap: { mobile: 2, laptop: '32px 24px' },
          justifyContent: 'center',
          maxWidth: 680,
          width: 1
        }}
      >
        {slice(1, 7, cards).map(card => (
          <CardHandler
            card={card}
            key={`${prop('id', card)}-${prop('section_id', card)}`}
            small
          />
        ))}
      </Stack>
    </Stack>
  )

CardGrid.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({})),
  selected: PropTypes.string
}

export default CardGrid
